/**
* Template Name: NiceAdmin - v2.3.1
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* inter font link */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import "../.././style.css/font.css";

:root {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  z-index: 1000;
  left: 50%;
  top: 50%;
  position: fixed;
}

body {
  font-family: "Open Sans", sans-serif;
  background: #f6f9ff;
  color: #444444;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.card::-webkit-scrollbar {
  height: 6px;
}

.scroll::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f9ff;
  /* background: #f1f1f1; */
}

.card::-webkit-scrollbar-track {
  background: #f6f9ff;
  /* background: #f1f1f1; */
}

.scroll::-webkit-scrollbar-track {
  background: #f6f9ff;
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8b8c8e;
  border-radius: 12px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #8b8c8e;
  border-radius: 12px;
}

.card::-webkit-scrollbar-thumb {
  background: #8b8c8e;
  border-radius: 12px;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  /* padding: 20px 30px; */
  transition: all 0.3s;
}

/* @media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
} */

.rotate-180 {
  transform: rotate(180deg) !important;
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*  chart styling */

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 1.5rem 1.5rem;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo {
  width: 280px;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #012970;
  font-family: "Nunito", sans-serif;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
  /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */
  border-bottom: 1px solid #e7eaf0;
  background-color: #fff;
  padding-left: 20px;
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 270px;
  /*z-index: 1000;*/
  z-index: 8;
  transition: all 0.3s;
  padding: 20px 0px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  /* box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1); */
  border-right: 1px solid #e7eaf0;
  background-color: #fff;
}

@media (max-width: 1000px) {
  /* .sidebar {
    left: -270px;
  } */
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1000px) {
  /* #main, */
  #footer {
    margin-left: 270px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #4154f1;
  transition: 0.3;
  background: #f6f9ff;
  padding: 12px 24px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  color: #4154f1;
}

.sidebar-nav .nav-link.collapsed {
  color: #16192c;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #012970;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #4154f1;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #012970;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item + .post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #012970;
  padding: 15px 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4154f1;
}

.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #5969f3;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

.mat-dialog-container {
  max-height: 800px !important;
  border-radius: 7px !important;
}

/* timesheet filter select starts here */
.timesheetFilter h5 {
  color: #16192c !important;
}

.filterSelect {
  width: auto !important;
  margin-top: 7px;
}

.filterSelect .mat-select-value span {
  font-size: 18px;
  color: #16192c;
  font-family: "Poppins", sans-serif;
}

.filterSelect .mat-select-arrow {
  color: #16192c;
}

.singleTimesheetOptionsSelect .mat-select-arrow,
.statusFilter .mat-select-arrow {
  display: none;
}

/* time sheet filter select ends here */
.sheetStatus {
  width: max-content;
  padding: 4px 14px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sheetStatus span {
  font-weight: 500;
  font-size: 12px;
}

.sheetStatus i {
  margin-right: 10px;
}

app-timesheets {
  padding: 0 !important;
}

.fs-7 {
  font-size: 0.75rem !important;
}

.statusFilterPanel span.mat-option-text {
  font-size: 0.7125rem;
}

.statusFilterPanel
  mat-pseudo-checkbox.mat-pseudo-checkbox.mat-option-pseudo-checkbox.ng-star-inserted {
  width: 12px;
  height: 12px;
}

.statusFilterPanel .mat-pseudo-checkbox-checked::after {
  top: 0.4px !important;
  left: 0px !important;
  width: 7px !important;
  height: 2px !important;
  border-left: 2px solid currentColor !important;
  transform: rotate(-45deg) !important;
  opacity: 1 !important;
  box-sizing: content-box !important;
}

.statusFilterPanel {
  width: 60px;
  right: 55px;
  top: 15px;
  position: relative;
}

.statusFilterPanel mat-option {
  height: 2.5em !important;
}

.view-client-modal .modal-content {
  background: #f5f9fc;
}

/* .modal {
  display: block !important;
}

.modal-dialog {
  overflow-y: initial !important
}

.modal-body {
  height: calc(100% - var(--bs-modal-margin) * 2);
  overflow-y: scroll;
  overflow-x: hidden;
} */

/* .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
} */

/* .view-client-modal,
.add-client-modal,
.update-client-modal {
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 700px !important;
  width: 94% !important;
} */

.add-reminder-modal {
  height: 100%;
  display: flex;
  align-items: center;
}

.add-reminder-modal .modal-content {
  max-height: 90%;
  overflow: hidden;
}

.add-reminder-modal .modal-content app-create-timesheet {
  overflow: scroll;
}

/* .alert-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.success-modal .modal-content {
  width: 350px;
  padding: 1rem;
}

.success-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rememberMe .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #0566ea !important;
}

.rememberMe span.mat-ripple-element.mat-checkbox-persistent-ripple {
  background: #0566ea !important;
}

app-paginator {
  width: 100%;
}

#invalidField {
  border: 1px solid red;
}

.dataColumn:hover {
  box-shadow: 0px 0px 10px #0000002b;
  background: #f7f7f7;
}

.textWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textWithIcon i {
  margin-right: 6px;
  font-size: 16px;
  color: #6b7b93;
}

.cotractorInitialIcon {
  border-radius: 100%;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cotractorInitialIcon span {
  font-weight: 100;
}

/* .textWithIcon img {
  width: 20px;
  margin-right: 4px;
} */
.clickable {
  cursor: pointer;
}

.invalidField {
  border-color: rgba(220, 53, 69, 0.9) !important;
}

.fs-10x {
  font-size: 10px !important;
}

.fs-16x {
  font-size: 16px !important;
}

.me-20x {
  margin-right: 20px !important;
}

mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(178 179 250) !important;
}

mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0566ea !important;
}

/* ngb-modal-window.d-block.modal.fade.show {
  background: #0000006d;
  z-index: 9999999999999999;
} */
ngb-modal-window:has(.alert-modal) {
  background: #0000006d;
  z-index: 1057;
}

.viewClientContacts {
  opacity: 0 !important;
}

.cdk-overlay-container:has(mat-dialog-container) {
  z-index: 1055;
}

.cdk-overlay-container:has(.mat-menu-panel) {
  z-index: 1055;
}

.cdk-overlay-container:has(.mat-select-panel) {
  z-index: 1056;
}

.cdk-overlay-container:has(mat-datepicker-content) {
  z-index: 1056;
}

ngx-material-timepicker-container {
  z-index: 1057;
  position: relative;
}

div.cdk-overlay-pane:has(.searchSelectPanel) {
  margin-top: 50px;
  margin-left: 8px;
}

div#matSelect-panel.searchSelectPanel {
  max-width: 400px;
  width: 400px;
  min-width: 400px;
}

.searchSelect .mat-select-trigger {
  width: 0;
}

.searchSelect .mat-select-arrow {
  display: none !important;
}

.timesheetDateFilterPanel .mat-option-text {
  color: #525f7f;
  font-size: 0.8125rem;
  font-family: "Inter";
}

mat-spinner.mat-progress-spinner circle,
mat-spinner.mat-spinner circle {
  stroke: #0566ea !important;
}

.shortcutsMenu.mat-menu-panel {
  width: 280px;
  min-height: 52px !important;
  margin-top: 10px;
}

.shortcutsMenu .mat-menu-content {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
}

.cdk-overlay-pane:has(.notificationPanel) {
  right: -55px;
  position: absolute !important;
  height: 80%;
}

.notificationPanel.mat-menu-panel {
  width: 471px;
  max-width: 471px;
  margin-top: 10px;
  box-shadow: -2px -2px 8px rgb(110 110 110 / 10%),
    2px 2px 8px rgb(110 110 110 / 10%);
  border-radius: 8px;
}

.notificationPanel .mat-menu-content {
  height: 100%;
}

app-notification-panel {
  height: 100%;
  display: block;
}

.mat-calendar-body-cell-content {
  top: 5%;
  left: 5%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 999px;
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-selected {
  color: #ffffff;
  background-color: #0566ea;
}

.mat-calendar-body-in-range::before {
  color: #ffffff;
  background: rgba(5, 102, 234, 0.3) !important;
}

.mat-calendar-body-in-range.mat-calendar-body-range-end
  .mat-calendar-body-cell-content {
  box-shadow: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgba(5, 102, 234, 0.3);
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background-color: rgba(5, 102, 234, 0.3);
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgba(5, 102, 234, 0.3);
  border-color: transparent;
}

.reminderForm mat-select .mat-select-trigger .mat-select-arrow-wrapper {
  display: none !important;
}

/* .mat-select-arrow-wrapper {
  display: none !important;
} */

.timesheetDetailDialog mat-dialog-container {
  max-height: 100% !important;
}

.requiredStar {
  color: red;
}

.tableTitle {
  color: #16192c !important;
}

.add-reminder-modal .modal-content:has(.taskCommentContainer) {
  min-height: 70%;
}

.add-reminder-modal:has(.taskCommentContainer) {
  max-width: 700px;
  width: 90%;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1 !important;
}

/***
  ********************************************
  ** Custom Css
  ********************************************
***/

.cursor-pointer {
  cursor: pointer;
}

.logo img {
  max-height: 26px;
  margin-right: 6px;
}

.image-upload-section {
  width: 100%;
  height: 100px;
}

.image-upload-section .image-upload {
  max-width: 120px;
  max-height: 120px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0566ea;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0566ea;
}

.mat-radio-container:hover .mat-radio-persistent-ripple {
  opacity: 0 !important;
}

.mat-radio-label-content {
  padding-left: 4px !important;
}

.table td {
  padding: 20px !important;
}

/*
** Alert Modal CSS
*/
.alert-modal .modal-content {
  background-color: transparent;
  border: none;
  border-radius: 7px;
}

.alert-modal.modal-dialog {
  max-width: 450px;
}

.alert-modal .modal-content .modal-body {
  border-radius: 7px;
  overflow: hidden;
  background-color: #fff;
  padding-left: 0px;
  padding-right: 0px;
  -webkit-box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.9);
}

.alert-modal .modal-content .modal-body h3 {
  color: #000;
  font-size: 22px;
}

.alert-modal .modal-content .modal-body .close-btn {
  color: #000;
}

.alert-modal .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
}

.alert-modal .close-btn span {
  color: #ccc;
}

.alert-modal .close-btn:hover span {
  color: #000;
}

.alert-modal .warp-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  color: #3e64ff;
  position: relative;
  border-radius: 50%;
  /* background: rgba(62, 100, 255, 0.05); */
}

.alert-modal .warp-icon i {
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: #dc3545;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.alert-modal .modal-content .modal-body h2 {
  font-size: 18px;
}

.alert-modal .modal-content .modal-body p {
  color: rgba(var(--x-muted-rgb), var(--x-text-opacity)) !important;
  font-size: 14px;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0566ea;
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    width: 270px;
  }
}
