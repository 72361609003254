@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Light.ttf") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Regular.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("woff2");
  font-weight: 700;
  font-style: normal;
}
