@charset "UTF-8";

:root {
    --x-gray-50: #fafafa;
    --x-gray-100: #f5f9fc;
    --x-gray-200: #e7eaf0;
    --x-gray-300: #cfd6df;
    --x-gray-400: #abb6c5;
    --x-gray-500: #8898a9;
    --x-gray-600: #6b7b93;
    --x-gray-700: #525f7f;
    --x-gray-800: #2d3748;
    --x-gray-900: #16192c;
    --x-black: #000;
    --x-blue-100: #ccebff;
    --x-blue-200: #99d6ff;
    --x-blue-300: #66c2ff;
    --x-blue-400: #33adff;
    --x-blue-500: #09f;
    --x-blue-600: #007acc;
    --x-blue-700: #005c99;
    --x-blue-800: #003d66;
    --x-blue-900: #001f33;
    --x-indigo-100: #dedffd;
    --x-indigo-200: #bebffb;
    --x-indigo-300: #9da0f9;
    --x-indigo-400: #7d80f7;
    --x-indigo-500: #0566ea;
    --x-indigo-600: #0b56bc;
    --x-indigo-700: #373a93;
    --x-indigo-800: #252662;
    --x-indigo-900: #121331;
    --x-purple-100: #e7ddff;
    --x-purple-200: #d0bcff;
    --x-purple-300: #b89aff;
    --x-purple-400: #a179ff;
    --x-purple-500: #8957ff;
    --x-purple-600: #6e46cc;
    --x-purple-700: #523499;
    --x-purple-800: #372366;
    --x-purple-900: #1b1133;
    --x-pink-100: #ffddeb;
    --x-pink-200: #ffbcd7;
    --x-pink-300: #ff9ac2;
    --x-pink-400: #ff79ae;
    --x-pink-500: #ff579a;
    --x-pink-600: #cc467b;
    --x-pink-700: #99345c;
    --x-pink-800: #66233e;
    --x-pink-900: #33111f;
    --x-magenta-100: #fcd6ff;
    --x-magenta-200: #f8adff;
    --x-magenta-300: #f585ff;
    --x-magenta-400: #f15cff;
    --x-magenta-500: #e3f;
    --x-magenta-600: #be29cc;
    --x-magenta-700: #8f1f99;
    --x-magenta-800: #5f1466;
    --x-magenta-900: #300a33;
    --x-red-100: #ffd6e0;
    --x-red-200: #ffadc2;
    --x-red-300: #ff85a3;
    --x-red-400: #ff5c85;
    --x-red-500: #dc3545;
    --x-red-600: #bd2130;
    --x-red-700: #991f3d;
    --x-red-800: #661429;
    --x-red-900: #330a14;
    --x-orange-100: #ffe8cc;
    --x-orange-200: #ffd199;
    --x-orange-300: #ffba66;
    --x-orange-400: #ffa333;
    --x-orange-500: #ff8c00;
    --x-orange-600: #cc7000;
    --x-orange-700: #995400;
    --x-orange-800: #663800;
    --x-orange-900: #331c00;
    --x-yellow-100: #fff1cc;
    --x-yellow-200: #ffe499;
    --x-yellow-300: #ffd666;
    --x-yellow-400: #ffc933;
    --x-yellow-500: #fb0;
    --x-yellow-600: #cc9600;
    --x-yellow-700: #997000;
    --x-yellow-800: #664b00;
    --x-yellow-900: #332500;
    --x-green-100: #ccf5e7;
    --x-green-200: #99ebcf;
    --x-green-300: #66e0b8;
    --x-green-400: #33d6a0;
    --x-green-500: #28a745;
    --x-green-600: #218838;
    --x-green-700: #007a52;
    --x-green-800: #005236;
    --x-green-900: #00291b;
    --x-teal-100: #d3f8f8;
    --x-teal-200: #a7f1f1;
    --x-teal-300: #7aebeb;
    --x-teal-400: #4ee4e4;
    --x-teal-500: #2dd;
    --x-teal-600: #1bb1b1;
    --x-teal-700: #148585;
    --x-teal-800: #0e5858;
    --x-teal-900: #072c2c;
    --x-cyan-100: #ccf6ff;
    --x-cyan-200: #9ef;
    --x-cyan-300: #66e5ff;
    --x-cyan-400: #3df;
    --x-cyan-500: #00d4ff;
    --x-cyan-600: #0ac;
    --x-cyan-700: #007f99;
    --x-cyan-800: #056;
    --x-cyan-900: #002a33;
    --x-primary: #0566ea;
    --x-shade-primary: #5356dd;
    --x-tint-primary: #6c70f6;
    --x-secondary: #cfd6df;
    --x-shade-secondary: #bac1c9;
    --x-tint-secondary: #d4dae2;
    --x-tertiary: #ff579a;
    --x-shade-tertiary: #e64e8b;
    --x-tint-tertiary: #ff68a4;
    --x-success: #28a745;
    --x-shade-success: #00b87a;
    --x-tint-success: #1ad194;
    --x-info: #00d4ff;
    --x-shade-info: #00bfe6;
    --x-tint-info: #1ad8ff;
    --x-warning: #ff8c00;
    --x-shade-warning: #e67e00;
    --x-tint-warning: #ff981a;
    --x-danger: #dc3545;
    --x-shade-danger: #e62e5c;
    --x-tint-danger: #ff4775;
    --x-white: #fff;
    --x-shade-white: #e6e6e6;
    --x-tint-white: #fff;
    --x-light: #fff;
    --x-shade-light: #e6e6e6;
    --x-tint-light: #fff;
    --x-dark: #16192c;
    --x-shade-dark: #141728;
    --x-tint-dark: #2d3041;
    --x-primary-rgb: 5, 102, 234;
    --x-secondary-rgb: 207, 214, 223;
    --x-tertiary-rgb: 255, 87, 154;
    --x-success-rgb: 0, 204, 136;
    --x-info-rgb: 0, 212, 255;
    --x-warning-rgb: 255, 140, 0;
    --x-danger-rgb: 255, 51, 102;
    --x-light-rgb: 255, 255, 255;
    --x-dark-rgb: 22, 25, 44;
    --x-surface-primary: #fff;
    --x-surface-secondary: #f5f9fc;
    --x-surface-tertiary: #fafafa;
    --x-surface-light: #e7eaf0;
    --x-surface-dark: #141729;
    --x-white-rgb: 255, 255, 255;
    --x-black-rgb: 0, 0, 0;
    --x-muted-rgb: 107, 123, 147;
    --x-body-rgb: 82, 95, 127;
    --x-heading-rgb: 22, 25, 44;
    --x-card-rgb: 255, 255, 255;
    --x-font-sans-serif: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --x-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --x-font-display: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    --x-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0));
    --x-body-font-family: var(--x-font-sans-serif);
    --x-body-font-size: 1rem;
    --x-body-font-weight: 400;
    --x-body-line-height: 1.625;
    --x-body-color: #525f7f;
    --x-body-bg: #fff;
}

*,
:after,
:before {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: var(--x-body-bg);
    color: var(--x-body-color);
    font-family: var(--x-body-font-family);
    font-size: var(--x-body-font-size);
    font-weight: var(--x-body-font-weight);
    line-height: var(--x-body-line-height);
    margin: 0;
    text-align: var(--x-body-text-align);
}

hr {
    background-color: currentColor;
    border: 0;
    color: #e7eaf0;
    margin: 1rem 0;
    opacity: 0.9;
}

hr:not([size]) {
    height: 1px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #16192c;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 0;
    margin-top: 0;
}

.h1,
h1 {
    font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

    .h1,
    h1 {
        font-size: 2.25rem;
    }
}

.h2,
h2 {
    font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {

    .h2,
    h2 {
        font-size: 1.75rem;
    }
}

.h3,
h3 {
    font-size: calc(1.2625rem + 0.15vw);
}

@media (min-width: 1200px) {

    .h3,
    h3 {
        font-size: 1.375rem;
    }
}

.h4,
h4 {
    font-size: 1.125rem;
}

.h5,
h5 {
    font-size: 1rem;
}

.h6,
h6 {
    font-size: 0.875rem;
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

abbr[data-bs-original-title],
abbr[title] {
    cursor: help;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem;
}

ol,
ul {
    padding-left: 2rem;
}

dl,
ol,
ul {
    margin-bottom: 1rem;
    margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 600;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

.small,
small {
    font-size: 0.875em;
}

.mark,
mark {
    background-color: #fcf8e3;
    padding: 0;
}

sub,
sup {
    font-size: 0.75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #0566ea;
}

a,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #4e52d0;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

code,
kbd,
pre,
samp {
    direction: ltr;
    font-family: var(--x-font-monospace);
    font-size: 1em;
    unicode-bidi: bidi-override;
}

pre {
    display: block;
    font-size: 0.875em;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto;
}

pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal;
}

code {
    word-wrap: break-word;
    color: #ff579a;
    font-size: 0.875em;
}

a>code {
    color: inherit;
}

kbd {
    background-color: #16192c;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.875em;
    padding: 0.2rem 0.4rem;
}

kbd kbd {
    font-size: 1em;
    font-weight: 600;
    padding: 0;
}

figure {
    margin: 0 0 1rem;
}

img,
svg {
    vertical-align: middle;
}

table {
    border-collapse: collapse;
    caption-side: bottom;
}

caption {
    color: #6b7b93;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: left;
}

th {
    font-weight: 500;
    text-align: inherit;
    text-align: -webkit-match-parent;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border: 0 solid;
    border-color: inherit;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

button,
select {
    text-transform: none;
}

[role="button"] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

select:disabled {
    opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

textarea {
    resize: vertical;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

legend {
    float: left;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
    margin-bottom: 0.5rem;
    padding: 0;
    width: 100%;
}

@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}

legend+* {
    clear: left;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
}

::file-selector-button {
    font: inherit;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

output {
    display: inline-block;
}

iframe {
    border: 0;
}

summary {
    cursor: pointer;
    display: list-item;
}

progress {
    vertical-align: baseline;
}

[hidden] {
    display: none !important;
}

*,
:after,
:before {
    border: 0 solid #e7eaf0;
}

iframe {
    display: block;
    vertical-align: middle;
}

figcaption,
figure,
main {
    display: block;
    margin: 0;
}

figure {
    max-width: 100%;
    min-height: 1px;
}

img,
video {
    height: auto;
    max-width: 100%;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

a {
    outline: none;
}

.img-fluid,
.img-thumbnail {
    height: auto;
    max-width: 100%;
}

.img-thumbnail {
    background-color: #fff;
    border: 1px solid #cfd6df;
    border-radius: 0.375rem;
    box-shadow: 0 1px 1px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    padding: 0.25rem;
}

.figure {
    display: inline-block;
}

.figure-img {
    line-height: 1;
    margin-bottom: 0.5rem;
}

.figure-caption {
    color: #6b7b93;
    font-size: 0.875em;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--x-gutter-x, 1.5rem);
    padding-right: var(--x-gutter-x, 1.5rem);
    width: 100%;
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1360px;
    }
}

.row {
    --x-gutter-x: 1.5rem;
    --x-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--x-gutter-x) * -0.5);
    margin-right: calc(var(--x-gutter-x) * -0.5);
    margin-top: calc(var(--x-gutter-y) * -1);
}

.row>* {
    flex-shrink: 0;
    margin-top: var(--x-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--x-gutter-x) * 0.5);
    padding-right: calc(var(--x-gutter-x) * 0.5);
    width: 100%;
}

.grid {
    display: grid;
    gap: var(--x-gap, 1.5rem);
    grid-template-columns: repeat(var(--x-columns, 12), 1fr);
    grid-template-rows: repeat(var(--x-rows, 1), 1fr);
}

.grid .g-col-1 {
    grid-column: auto/span 1;
}

.grid .g-col-2 {
    grid-column: auto/span 2;
}

.grid .g-col-3 {
    grid-column: auto/span 3;
}

.grid .g-col-4 {
    grid-column: auto/span 4;
}

.grid .g-col-5 {
    grid-column: auto/span 5;
}

.grid .g-col-6 {
    grid-column: auto/span 6;
}

.grid .g-col-7 {
    grid-column: auto/span 7;
}

.grid .g-col-8 {
    grid-column: auto/span 8;
}

.grid .g-col-9 {
    grid-column: auto/span 9;
}

.grid .g-col-10 {
    grid-column: auto/span 10;
}

.grid .g-col-11 {
    grid-column: auto/span 11;
}

.grid .g-col-12 {
    grid-column: auto/span 12;
}

.grid .g-start-1 {
    grid-column-start: 1;
}

.grid .g-start-2 {
    grid-column-start: 2;
}

.grid .g-start-3 {
    grid-column-start: 3;
}

.grid .g-start-4 {
    grid-column-start: 4;
}

.grid .g-start-5 {
    grid-column-start: 5;
}

.grid .g-start-6 {
    grid-column-start: 6;
}

.grid .g-start-7 {
    grid-column-start: 7;
}

.grid .g-start-8 {
    grid-column-start: 8;
}

.grid .g-start-9 {
    grid-column-start: 9;
}

.grid .g-start-10 {
    grid-column-start: 10;
}

.grid .g-start-11 {
    grid-column-start: 11;
}

@media (min-width: 576px) {
    .grid .g-col-sm-1 {
        grid-column: auto/span 1;
    }

    .grid .g-col-sm-2 {
        grid-column: auto/span 2;
    }

    .grid .g-col-sm-3 {
        grid-column: auto/span 3;
    }

    .grid .g-col-sm-4 {
        grid-column: auto/span 4;
    }

    .grid .g-col-sm-5 {
        grid-column: auto/span 5;
    }

    .grid .g-col-sm-6 {
        grid-column: auto/span 6;
    }

    .grid .g-col-sm-7 {
        grid-column: auto/span 7;
    }

    .grid .g-col-sm-8 {
        grid-column: auto/span 8;
    }

    .grid .g-col-sm-9 {
        grid-column: auto/span 9;
    }

    .grid .g-col-sm-10 {
        grid-column: auto/span 10;
    }

    .grid .g-col-sm-11 {
        grid-column: auto/span 11;
    }

    .grid .g-col-sm-12 {
        grid-column: auto/span 12;
    }

    .grid .g-start-sm-1 {
        grid-column-start: 1;
    }

    .grid .g-start-sm-2 {
        grid-column-start: 2;
    }

    .grid .g-start-sm-3 {
        grid-column-start: 3;
    }

    .grid .g-start-sm-4 {
        grid-column-start: 4;
    }

    .grid .g-start-sm-5 {
        grid-column-start: 5;
    }

    .grid .g-start-sm-6 {
        grid-column-start: 6;
    }

    .grid .g-start-sm-7 {
        grid-column-start: 7;
    }

    .grid .g-start-sm-8 {
        grid-column-start: 8;
    }

    .grid .g-start-sm-9 {
        grid-column-start: 9;
    }

    .grid .g-start-sm-10 {
        grid-column-start: 10;
    }

    .grid .g-start-sm-11 {
        grid-column-start: 11;
    }
}

@media (min-width: 768px) {
    .grid .g-col-md-1 {
        grid-column: auto/span 1;
    }

    .grid .g-col-md-2 {
        grid-column: auto/span 2;
    }

    .grid .g-col-md-3 {
        grid-column: auto/span 3;
    }

    .grid .g-col-md-4 {
        grid-column: auto/span 4;
    }

    .grid .g-col-md-5 {
        grid-column: auto/span 5;
    }

    .grid .g-col-md-6 {
        grid-column: auto/span 6;
    }

    .grid .g-col-md-7 {
        grid-column: auto/span 7;
    }

    .grid .g-col-md-8 {
        grid-column: auto/span 8;
    }

    .grid .g-col-md-9 {
        grid-column: auto/span 9;
    }

    .grid .g-col-md-10 {
        grid-column: auto/span 10;
    }

    .grid .g-col-md-11 {
        grid-column: auto/span 11;
    }

    .grid .g-col-md-12 {
        grid-column: auto/span 12;
    }

    .grid .g-start-md-1 {
        grid-column-start: 1;
    }

    .grid .g-start-md-2 {
        grid-column-start: 2;
    }

    .grid .g-start-md-3 {
        grid-column-start: 3;
    }

    .grid .g-start-md-4 {
        grid-column-start: 4;
    }

    .grid .g-start-md-5 {
        grid-column-start: 5;
    }

    .grid .g-start-md-6 {
        grid-column-start: 6;
    }

    .grid .g-start-md-7 {
        grid-column-start: 7;
    }

    .grid .g-start-md-8 {
        grid-column-start: 8;
    }

    .grid .g-start-md-9 {
        grid-column-start: 9;
    }

    .grid .g-start-md-10 {
        grid-column-start: 10;
    }

    .grid .g-start-md-11 {
        grid-column-start: 11;
    }
}

@media (min-width: 992px) {
    .grid .g-col-lg-1 {
        grid-column: auto/span 1;
    }

    .grid .g-col-lg-2 {
        grid-column: auto/span 2;
    }

    .grid .g-col-lg-3 {
        grid-column: auto/span 3;
    }

    .grid .g-col-lg-4 {
        grid-column: auto/span 4;
    }

    .grid .g-col-lg-5 {
        grid-column: auto/span 5;
    }

    .grid .g-col-lg-6 {
        grid-column: auto/span 6;
    }

    .grid .g-col-lg-7 {
        grid-column: auto/span 7;
    }

    .grid .g-col-lg-8 {
        grid-column: auto/span 8;
    }

    .grid .g-col-lg-9 {
        grid-column: auto/span 9;
    }

    .grid .g-col-lg-10 {
        grid-column: auto/span 10;
    }

    .grid .g-col-lg-11 {
        grid-column: auto/span 11;
    }

    .grid .g-col-lg-12 {
        grid-column: auto/span 12;
    }

    .grid .g-start-lg-1 {
        grid-column-start: 1;
    }

    .grid .g-start-lg-2 {
        grid-column-start: 2;
    }

    .grid .g-start-lg-3 {
        grid-column-start: 3;
    }

    .grid .g-start-lg-4 {
        grid-column-start: 4;
    }

    .grid .g-start-lg-5 {
        grid-column-start: 5;
    }

    .grid .g-start-lg-6 {
        grid-column-start: 6;
    }

    .grid .g-start-lg-7 {
        grid-column-start: 7;
    }

    .grid .g-start-lg-8 {
        grid-column-start: 8;
    }

    .grid .g-start-lg-9 {
        grid-column-start: 9;
    }

    .grid .g-start-lg-10 {
        grid-column-start: 10;
    }

    .grid .g-start-lg-11 {
        grid-column-start: 11;
    }
}

@media (min-width: 1200px) {
    .grid .g-col-xl-1 {
        grid-column: auto/span 1;
    }

    .grid .g-col-xl-2 {
        grid-column: auto/span 2;
    }

    .grid .g-col-xl-3 {
        grid-column: auto/span 3;
    }

    .grid .g-col-xl-4 {
        grid-column: auto/span 4;
    }

    .grid .g-col-xl-5 {
        grid-column: auto/span 5;
    }

    .grid .g-col-xl-6 {
        grid-column: auto/span 6;
    }

    .grid .g-col-xl-7 {
        grid-column: auto/span 7;
    }

    .grid .g-col-xl-8 {
        grid-column: auto/span 8;
    }

    .grid .g-col-xl-9 {
        grid-column: auto/span 9;
    }

    .grid .g-col-xl-10 {
        grid-column: auto/span 10;
    }

    .grid .g-col-xl-11 {
        grid-column: auto/span 11;
    }

    .grid .g-col-xl-12 {
        grid-column: auto/span 12;
    }

    .grid .g-start-xl-1 {
        grid-column-start: 1;
    }

    .grid .g-start-xl-2 {
        grid-column-start: 2;
    }

    .grid .g-start-xl-3 {
        grid-column-start: 3;
    }

    .grid .g-start-xl-4 {
        grid-column-start: 4;
    }

    .grid .g-start-xl-5 {
        grid-column-start: 5;
    }

    .grid .g-start-xl-6 {
        grid-column-start: 6;
    }

    .grid .g-start-xl-7 {
        grid-column-start: 7;
    }

    .grid .g-start-xl-8 {
        grid-column-start: 8;
    }

    .grid .g-start-xl-9 {
        grid-column-start: 9;
    }

    .grid .g-start-xl-10 {
        grid-column-start: 10;
    }

    .grid .g-start-xl-11 {
        grid-column-start: 11;
    }
}

@media (min-width: 1400px) {
    .grid .g-col-xxl-1 {
        grid-column: auto/span 1;
    }

    .grid .g-col-xxl-2 {
        grid-column: auto/span 2;
    }

    .grid .g-col-xxl-3 {
        grid-column: auto/span 3;
    }

    .grid .g-col-xxl-4 {
        grid-column: auto/span 4;
    }

    .grid .g-col-xxl-5 {
        grid-column: auto/span 5;
    }

    .grid .g-col-xxl-6 {
        grid-column: auto/span 6;
    }

    .grid .g-col-xxl-7 {
        grid-column: auto/span 7;
    }

    .grid .g-col-xxl-8 {
        grid-column: auto/span 8;
    }

    .grid .g-col-xxl-9 {
        grid-column: auto/span 9;
    }

    .grid .g-col-xxl-10 {
        grid-column: auto/span 10;
    }

    .grid .g-col-xxl-11 {
        grid-column: auto/span 11;
    }

    .grid .g-col-xxl-12 {
        grid-column: auto/span 12;
    }

    .grid .g-start-xxl-1 {
        grid-column-start: 1;
    }

    .grid .g-start-xxl-2 {
        grid-column-start: 2;
    }

    .grid .g-start-xxl-3 {
        grid-column-start: 3;
    }

    .grid .g-start-xxl-4 {
        grid-column-start: 4;
    }

    .grid .g-start-xxl-5 {
        grid-column-start: 5;
    }

    .grid .g-start-xxl-6 {
        grid-column-start: 6;
    }

    .grid .g-start-xxl-7 {
        grid-column-start: 7;
    }

    .grid .g-start-xxl-8 {
        grid-column-start: 8;
    }

    .grid .g-start-xxl-9 {
        grid-column-start: 9;
    }

    .grid .g-start-xxl-10 {
        grid-column-start: 10;
    }

    .grid .g-start-xxl-11 {
        grid-column-start: 11;
    }
}

.col {
    flex: 1 0 0%;
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto;
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%;
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%;
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%;
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%;
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.offset-1 {
    margin-left: 8.33333333%;
}

.offset-2 {
    margin-left: 16.66666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333333%;
}

.offset-5 {
    margin-left: 41.66666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333333%;
}

.offset-8 {
    margin-left: 66.66666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333333%;
}

.offset-11 {
    margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
    --x-gutter-x: 0;
}

.g-0,
.gy-0 {
    --x-gutter-y: 0;
}

.g-1,
.gx-1 {
    --x-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
    --x-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
    --x-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
    --x-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
    --x-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
    --x-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
    --x-gutter-x: 1rem;
}

.g-4,
.gy-4 {
    --x-gutter-y: 1rem;
}

.g-5,
.gx-5 {
    --x-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
    --x-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
    --x-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
    --x-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
    --x-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
    --x-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
    --x-gutter-x: 2rem;
}

.g-8,
.gy-8 {
    --x-gutter-y: 2rem;
}

.g-10,
.gx-10 {
    --x-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
    --x-gutter-y: 2.5rem;
}

.g-12,
.gx-12 {
    --x-gutter-x: 3rem;
}

.g-12,
.gy-12 {
    --x-gutter-y: 3rem;
}

.g-14,
.gx-14 {
    --x-gutter-x: 3.5rem;
}

.g-14,
.gy-14 {
    --x-gutter-y: 3.5rem;
}

.g-16,
.gx-16 {
    --x-gutter-x: 4rem;
}

.g-16,
.gy-16 {
    --x-gutter-y: 4rem;
}

.g-18,
.gx-18 {
    --x-gutter-x: 4.5rem;
}

.g-18,
.gy-18 {
    --x-gutter-y: 4.5rem;
}

.g-20,
.gx-20 {
    --x-gutter-x: 5rem;
}

.g-20,
.gy-20 {
    --x-gutter-y: 5rem;
}

.g-24,
.gx-24 {
    --x-gutter-x: 6rem;
}

.g-24,
.gy-24 {
    --x-gutter-y: 6rem;
}

.g-32,
.gx-32 {
    --x-gutter-x: 8rem;
}

.g-32,
.gy-32 {
    --x-gutter-y: 8rem;
}

.g-40,
.gx-40 {
    --x-gutter-x: 10rem;
}

.g-40,
.gy-40 {
    --x-gutter-y: 10rem;
}

.g-48,
.gx-48 {
    --x-gutter-x: 12rem;
}

.g-48,
.gy-48 {
    --x-gutter-y: 12rem;
}

.g-56,
.gx-56 {
    --x-gutter-x: 14rem;
}

.g-56,
.gy-56 {
    --x-gutter-y: 14rem;
}

.g-64,
.gx-64 {
    --x-gutter-x: 16rem;
}

.g-64,
.gy-64 {
    --x-gutter-y: 16rem;
}

.g-72,
.gx-72 {
    --x-gutter-x: 18rem;
}

.g-72,
.gy-72 {
    --x-gutter-y: 18rem;
}

.g-80,
.gx-80 {
    --x-gutter-x: 20rem;
}

.g-80,
.gy-80 {
    --x-gutter-y: 20rem;
}

.g-88,
.gx-88 {
    --x-gutter-x: 22rem;
}

.g-88,
.gy-88 {
    --x-gutter-y: 22rem;
}

.g-96,
.gx-96 {
    --x-gutter-x: 24rem;
}

.g-96,
.gy-96 {
    --x-gutter-y: 24rem;
}

.g-px,
.gx-px {
    --x-gutter-x: 1px;
}

.g-px,
.gy-px {
    --x-gutter-y: 1px;
}

@media (min-width: 576px) {
    .col-sm {
        flex: 1 0 0%;
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333333%;
    }

    .offset-sm-2 {
        margin-left: 16.66666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333333%;
    }

    .offset-sm-5 {
        margin-left: 41.66666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333333%;
    }

    .offset-sm-8 {
        margin-left: 66.66666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333333%;
    }

    .offset-sm-11 {
        margin-left: 91.66666667%;
    }

    .g-sm-0,
    .gx-sm-0 {
        --x-gutter-x: 0;
    }

    .g-sm-0,
    .gy-sm-0 {
        --x-gutter-y: 0;
    }

    .g-sm-1,
    .gx-sm-1 {
        --x-gutter-x: 0.25rem;
    }

    .g-sm-1,
    .gy-sm-1 {
        --x-gutter-y: 0.25rem;
    }

    .g-sm-2,
    .gx-sm-2 {
        --x-gutter-x: 0.5rem;
    }

    .g-sm-2,
    .gy-sm-2 {
        --x-gutter-y: 0.5rem;
    }

    .g-sm-3,
    .gx-sm-3 {
        --x-gutter-x: 0.75rem;
    }

    .g-sm-3,
    .gy-sm-3 {
        --x-gutter-y: 0.75rem;
    }

    .g-sm-4,
    .gx-sm-4 {
        --x-gutter-x: 1rem;
    }

    .g-sm-4,
    .gy-sm-4 {
        --x-gutter-y: 1rem;
    }

    .g-sm-5,
    .gx-sm-5 {
        --x-gutter-x: 1.25rem;
    }

    .g-sm-5,
    .gy-sm-5 {
        --x-gutter-y: 1.25rem;
    }

    .g-sm-6,
    .gx-sm-6 {
        --x-gutter-x: 1.5rem;
    }

    .g-sm-6,
    .gy-sm-6 {
        --x-gutter-y: 1.5rem;
    }

    .g-sm-7,
    .gx-sm-7 {
        --x-gutter-x: 1.75rem;
    }

    .g-sm-7,
    .gy-sm-7 {
        --x-gutter-y: 1.75rem;
    }

    .g-sm-8,
    .gx-sm-8 {
        --x-gutter-x: 2rem;
    }

    .g-sm-8,
    .gy-sm-8 {
        --x-gutter-y: 2rem;
    }

    .g-sm-10,
    .gx-sm-10 {
        --x-gutter-x: 2.5rem;
    }

    .g-sm-10,
    .gy-sm-10 {
        --x-gutter-y: 2.5rem;
    }

    .g-sm-12,
    .gx-sm-12 {
        --x-gutter-x: 3rem;
    }

    .g-sm-12,
    .gy-sm-12 {
        --x-gutter-y: 3rem;
    }

    .g-sm-14,
    .gx-sm-14 {
        --x-gutter-x: 3.5rem;
    }

    .g-sm-14,
    .gy-sm-14 {
        --x-gutter-y: 3.5rem;
    }

    .g-sm-16,
    .gx-sm-16 {
        --x-gutter-x: 4rem;
    }

    .g-sm-16,
    .gy-sm-16 {
        --x-gutter-y: 4rem;
    }

    .g-sm-18,
    .gx-sm-18 {
        --x-gutter-x: 4.5rem;
    }

    .g-sm-18,
    .gy-sm-18 {
        --x-gutter-y: 4.5rem;
    }

    .g-sm-20,
    .gx-sm-20 {
        --x-gutter-x: 5rem;
    }

    .g-sm-20,
    .gy-sm-20 {
        --x-gutter-y: 5rem;
    }

    .g-sm-24,
    .gx-sm-24 {
        --x-gutter-x: 6rem;
    }

    .g-sm-24,
    .gy-sm-24 {
        --x-gutter-y: 6rem;
    }

    .g-sm-32,
    .gx-sm-32 {
        --x-gutter-x: 8rem;
    }

    .g-sm-32,
    .gy-sm-32 {
        --x-gutter-y: 8rem;
    }

    .g-sm-40,
    .gx-sm-40 {
        --x-gutter-x: 10rem;
    }

    .g-sm-40,
    .gy-sm-40 {
        --x-gutter-y: 10rem;
    }

    .g-sm-48,
    .gx-sm-48 {
        --x-gutter-x: 12rem;
    }

    .g-sm-48,
    .gy-sm-48 {
        --x-gutter-y: 12rem;
    }

    .g-sm-56,
    .gx-sm-56 {
        --x-gutter-x: 14rem;
    }

    .g-sm-56,
    .gy-sm-56 {
        --x-gutter-y: 14rem;
    }

    .g-sm-64,
    .gx-sm-64 {
        --x-gutter-x: 16rem;
    }

    .g-sm-64,
    .gy-sm-64 {
        --x-gutter-y: 16rem;
    }

    .g-sm-72,
    .gx-sm-72 {
        --x-gutter-x: 18rem;
    }

    .g-sm-72,
    .gy-sm-72 {
        --x-gutter-y: 18rem;
    }

    .g-sm-80,
    .gx-sm-80 {
        --x-gutter-x: 20rem;
    }

    .g-sm-80,
    .gy-sm-80 {
        --x-gutter-y: 20rem;
    }

    .g-sm-88,
    .gx-sm-88 {
        --x-gutter-x: 22rem;
    }

    .g-sm-88,
    .gy-sm-88 {
        --x-gutter-y: 22rem;
    }

    .g-sm-96,
    .gx-sm-96 {
        --x-gutter-x: 24rem;
    }

    .g-sm-96,
    .gy-sm-96 {
        --x-gutter-y: 24rem;
    }

    .g-sm-px,
    .gx-sm-px {
        --x-gutter-x: 1px;
    }

    .g-sm-px,
    .gy-sm-px {
        --x-gutter-y: 1px;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex: 1 0 0%;
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333333%;
    }

    .offset-md-2 {
        margin-left: 16.66666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333333%;
    }

    .offset-md-5 {
        margin-left: 41.66666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333333%;
    }

    .offset-md-8 {
        margin-left: 66.66666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333333%;
    }

    .offset-md-11 {
        margin-left: 91.66666667%;
    }

    .g-md-0,
    .gx-md-0 {
        --x-gutter-x: 0;
    }

    .g-md-0,
    .gy-md-0 {
        --x-gutter-y: 0;
    }

    .g-md-1,
    .gx-md-1 {
        --x-gutter-x: 0.25rem;
    }

    .g-md-1,
    .gy-md-1 {
        --x-gutter-y: 0.25rem;
    }

    .g-md-2,
    .gx-md-2 {
        --x-gutter-x: 0.5rem;
    }

    .g-md-2,
    .gy-md-2 {
        --x-gutter-y: 0.5rem;
    }

    .g-md-3,
    .gx-md-3 {
        --x-gutter-x: 0.75rem;
    }

    .g-md-3,
    .gy-md-3 {
        --x-gutter-y: 0.75rem;
    }

    .g-md-4,
    .gx-md-4 {
        --x-gutter-x: 1rem;
    }

    .g-md-4,
    .gy-md-4 {
        --x-gutter-y: 1rem;
    }

    .g-md-5,
    .gx-md-5 {
        --x-gutter-x: 1.25rem;
    }

    .g-md-5,
    .gy-md-5 {
        --x-gutter-y: 1.25rem;
    }

    .g-md-6,
    .gx-md-6 {
        --x-gutter-x: 1.5rem;
    }

    .g-md-6,
    .gy-md-6 {
        --x-gutter-y: 1.5rem;
    }

    .g-md-7,
    .gx-md-7 {
        --x-gutter-x: 1.75rem;
    }

    .g-md-7,
    .gy-md-7 {
        --x-gutter-y: 1.75rem;
    }

    .g-md-8,
    .gx-md-8 {
        --x-gutter-x: 2rem;
    }

    .g-md-8,
    .gy-md-8 {
        --x-gutter-y: 2rem;
    }

    .g-md-10,
    .gx-md-10 {
        --x-gutter-x: 2.5rem;
    }

    .g-md-10,
    .gy-md-10 {
        --x-gutter-y: 2.5rem;
    }

    .g-md-12,
    .gx-md-12 {
        --x-gutter-x: 3rem;
    }

    .g-md-12,
    .gy-md-12 {
        --x-gutter-y: 3rem;
    }

    .g-md-14,
    .gx-md-14 {
        --x-gutter-x: 3.5rem;
    }

    .g-md-14,
    .gy-md-14 {
        --x-gutter-y: 3.5rem;
    }

    .g-md-16,
    .gx-md-16 {
        --x-gutter-x: 4rem;
    }

    .g-md-16,
    .gy-md-16 {
        --x-gutter-y: 4rem;
    }

    .g-md-18,
    .gx-md-18 {
        --x-gutter-x: 4.5rem;
    }

    .g-md-18,
    .gy-md-18 {
        --x-gutter-y: 4.5rem;
    }

    .g-md-20,
    .gx-md-20 {
        --x-gutter-x: 5rem;
    }

    .g-md-20,
    .gy-md-20 {
        --x-gutter-y: 5rem;
    }

    .g-md-24,
    .gx-md-24 {
        --x-gutter-x: 6rem;
    }

    .g-md-24,
    .gy-md-24 {
        --x-gutter-y: 6rem;
    }

    .g-md-32,
    .gx-md-32 {
        --x-gutter-x: 8rem;
    }

    .g-md-32,
    .gy-md-32 {
        --x-gutter-y: 8rem;
    }

    .g-md-40,
    .gx-md-40 {
        --x-gutter-x: 10rem;
    }

    .g-md-40,
    .gy-md-40 {
        --x-gutter-y: 10rem;
    }

    .g-md-48,
    .gx-md-48 {
        --x-gutter-x: 12rem;
    }

    .g-md-48,
    .gy-md-48 {
        --x-gutter-y: 12rem;
    }

    .g-md-56,
    .gx-md-56 {
        --x-gutter-x: 14rem;
    }

    .g-md-56,
    .gy-md-56 {
        --x-gutter-y: 14rem;
    }

    .g-md-64,
    .gx-md-64 {
        --x-gutter-x: 16rem;
    }

    .g-md-64,
    .gy-md-64 {
        --x-gutter-y: 16rem;
    }

    .g-md-72,
    .gx-md-72 {
        --x-gutter-x: 18rem;
    }

    .g-md-72,
    .gy-md-72 {
        --x-gutter-y: 18rem;
    }

    .g-md-80,
    .gx-md-80 {
        --x-gutter-x: 20rem;
    }

    .g-md-80,
    .gy-md-80 {
        --x-gutter-y: 20rem;
    }

    .g-md-88,
    .gx-md-88 {
        --x-gutter-x: 22rem;
    }

    .g-md-88,
    .gy-md-88 {
        --x-gutter-y: 22rem;
    }

    .g-md-96,
    .gx-md-96 {
        --x-gutter-x: 24rem;
    }

    .g-md-96,
    .gy-md-96 {
        --x-gutter-y: 24rem;
    }

    .g-md-px,
    .gx-md-px {
        --x-gutter-x: 1px;
    }

    .g-md-px,
    .gy-md-px {
        --x-gutter-y: 1px;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex: 1 0 0%;
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333333%;
    }

    .offset-lg-2 {
        margin-left: 16.66666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333333%;
    }

    .offset-lg-5 {
        margin-left: 41.66666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333333%;
    }

    .offset-lg-8 {
        margin-left: 66.66666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333333%;
    }

    .offset-lg-11 {
        margin-left: 91.66666667%;
    }

    .g-lg-0,
    .gx-lg-0 {
        --x-gutter-x: 0;
    }

    .g-lg-0,
    .gy-lg-0 {
        --x-gutter-y: 0;
    }

    .g-lg-1,
    .gx-lg-1 {
        --x-gutter-x: 0.25rem;
    }

    .g-lg-1,
    .gy-lg-1 {
        --x-gutter-y: 0.25rem;
    }

    .g-lg-2,
    .gx-lg-2 {
        --x-gutter-x: 0.5rem;
    }

    .g-lg-2,
    .gy-lg-2 {
        --x-gutter-y: 0.5rem;
    }

    .g-lg-3,
    .gx-lg-3 {
        --x-gutter-x: 0.75rem;
    }

    .g-lg-3,
    .gy-lg-3 {
        --x-gutter-y: 0.75rem;
    }

    .g-lg-4,
    .gx-lg-4 {
        --x-gutter-x: 1rem;
    }

    .g-lg-4,
    .gy-lg-4 {
        --x-gutter-y: 1rem;
    }

    .g-lg-5,
    .gx-lg-5 {
        --x-gutter-x: 1.25rem;
    }

    .g-lg-5,
    .gy-lg-5 {
        --x-gutter-y: 1.25rem;
    }

    .g-lg-6,
    .gx-lg-6 {
        --x-gutter-x: 1.5rem;
    }

    .g-lg-6,
    .gy-lg-6 {
        --x-gutter-y: 1.5rem;
    }

    .g-lg-7,
    .gx-lg-7 {
        --x-gutter-x: 1.75rem;
    }

    .g-lg-7,
    .gy-lg-7 {
        --x-gutter-y: 1.75rem;
    }

    .g-lg-8,
    .gx-lg-8 {
        --x-gutter-x: 2rem;
    }

    .g-lg-8,
    .gy-lg-8 {
        --x-gutter-y: 2rem;
    }

    .g-lg-10,
    .gx-lg-10 {
        --x-gutter-x: 2.5rem;
    }

    .g-lg-10,
    .gy-lg-10 {
        --x-gutter-y: 2.5rem;
    }

    .g-lg-12,
    .gx-lg-12 {
        --x-gutter-x: 3rem;
    }

    .g-lg-12,
    .gy-lg-12 {
        --x-gutter-y: 3rem;
    }

    .g-lg-14,
    .gx-lg-14 {
        --x-gutter-x: 3.5rem;
    }

    .g-lg-14,
    .gy-lg-14 {
        --x-gutter-y: 3.5rem;
    }

    .g-lg-16,
    .gx-lg-16 {
        --x-gutter-x: 4rem;
    }

    .g-lg-16,
    .gy-lg-16 {
        --x-gutter-y: 4rem;
    }

    .g-lg-18,
    .gx-lg-18 {
        --x-gutter-x: 4.5rem;
    }

    .g-lg-18,
    .gy-lg-18 {
        --x-gutter-y: 4.5rem;
    }

    .g-lg-20,
    .gx-lg-20 {
        --x-gutter-x: 5rem;
    }

    .g-lg-20,
    .gy-lg-20 {
        --x-gutter-y: 5rem;
    }

    .g-lg-24,
    .gx-lg-24 {
        --x-gutter-x: 6rem;
    }

    .g-lg-24,
    .gy-lg-24 {
        --x-gutter-y: 6rem;
    }

    .g-lg-32,
    .gx-lg-32 {
        --x-gutter-x: 8rem;
    }

    .g-lg-32,
    .gy-lg-32 {
        --x-gutter-y: 8rem;
    }

    .g-lg-40,
    .gx-lg-40 {
        --x-gutter-x: 10rem;
    }

    .g-lg-40,
    .gy-lg-40 {
        --x-gutter-y: 10rem;
    }

    .g-lg-48,
    .gx-lg-48 {
        --x-gutter-x: 12rem;
    }

    .g-lg-48,
    .gy-lg-48 {
        --x-gutter-y: 12rem;
    }

    .g-lg-56,
    .gx-lg-56 {
        --x-gutter-x: 14rem;
    }

    .g-lg-56,
    .gy-lg-56 {
        --x-gutter-y: 14rem;
    }

    .g-lg-64,
    .gx-lg-64 {
        --x-gutter-x: 16rem;
    }

    .g-lg-64,
    .gy-lg-64 {
        --x-gutter-y: 16rem;
    }

    .g-lg-72,
    .gx-lg-72 {
        --x-gutter-x: 18rem;
    }

    .g-lg-72,
    .gy-lg-72 {
        --x-gutter-y: 18rem;
    }

    .g-lg-80,
    .gx-lg-80 {
        --x-gutter-x: 20rem;
    }

    .g-lg-80,
    .gy-lg-80 {
        --x-gutter-y: 20rem;
    }

    .g-lg-88,
    .gx-lg-88 {
        --x-gutter-x: 22rem;
    }

    .g-lg-88,
    .gy-lg-88 {
        --x-gutter-y: 22rem;
    }

    .g-lg-96,
    .gx-lg-96 {
        --x-gutter-x: 24rem;
    }

    .g-lg-96,
    .gy-lg-96 {
        --x-gutter-y: 24rem;
    }

    .g-lg-px,
    .gx-lg-px {
        --x-gutter-x: 1px;
    }

    .g-lg-px,
    .gy-lg-px {
        --x-gutter-y: 1px;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%;
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xl-11 {
        margin-left: 91.66666667%;
    }

    .g-xl-0,
    .gx-xl-0 {
        --x-gutter-x: 0;
    }

    .g-xl-0,
    .gy-xl-0 {
        --x-gutter-y: 0;
    }

    .g-xl-1,
    .gx-xl-1 {
        --x-gutter-x: 0.25rem;
    }

    .g-xl-1,
    .gy-xl-1 {
        --x-gutter-y: 0.25rem;
    }

    .g-xl-2,
    .gx-xl-2 {
        --x-gutter-x: 0.5rem;
    }

    .g-xl-2,
    .gy-xl-2 {
        --x-gutter-y: 0.5rem;
    }

    .g-xl-3,
    .gx-xl-3 {
        --x-gutter-x: 0.75rem;
    }

    .g-xl-3,
    .gy-xl-3 {
        --x-gutter-y: 0.75rem;
    }

    .g-xl-4,
    .gx-xl-4 {
        --x-gutter-x: 1rem;
    }

    .g-xl-4,
    .gy-xl-4 {
        --x-gutter-y: 1rem;
    }

    .g-xl-5,
    .gx-xl-5 {
        --x-gutter-x: 1.25rem;
    }

    .g-xl-5,
    .gy-xl-5 {
        --x-gutter-y: 1.25rem;
    }

    .g-xl-6,
    .gx-xl-6 {
        --x-gutter-x: 1.5rem;
    }

    .g-xl-6,
    .gy-xl-6 {
        --x-gutter-y: 1.5rem;
    }

    .g-xl-7,
    .gx-xl-7 {
        --x-gutter-x: 1.75rem;
    }

    .g-xl-7,
    .gy-xl-7 {
        --x-gutter-y: 1.75rem;
    }

    .g-xl-8,
    .gx-xl-8 {
        --x-gutter-x: 2rem;
    }

    .g-xl-8,
    .gy-xl-8 {
        --x-gutter-y: 2rem;
    }

    .g-xl-10,
    .gx-xl-10 {
        --x-gutter-x: 2.5rem;
    }

    .g-xl-10,
    .gy-xl-10 {
        --x-gutter-y: 2.5rem;
    }

    .g-xl-12,
    .gx-xl-12 {
        --x-gutter-x: 3rem;
    }

    .g-xl-12,
    .gy-xl-12 {
        --x-gutter-y: 3rem;
    }

    .g-xl-14,
    .gx-xl-14 {
        --x-gutter-x: 3.5rem;
    }

    .g-xl-14,
    .gy-xl-14 {
        --x-gutter-y: 3.5rem;
    }

    .g-xl-16,
    .gx-xl-16 {
        --x-gutter-x: 4rem;
    }

    .g-xl-16,
    .gy-xl-16 {
        --x-gutter-y: 4rem;
    }

    .g-xl-18,
    .gx-xl-18 {
        --x-gutter-x: 4.5rem;
    }

    .g-xl-18,
    .gy-xl-18 {
        --x-gutter-y: 4.5rem;
    }

    .g-xl-20,
    .gx-xl-20 {
        --x-gutter-x: 5rem;
    }

    .g-xl-20,
    .gy-xl-20 {
        --x-gutter-y: 5rem;
    }

    .g-xl-24,
    .gx-xl-24 {
        --x-gutter-x: 6rem;
    }

    .g-xl-24,
    .gy-xl-24 {
        --x-gutter-y: 6rem;
    }

    .g-xl-32,
    .gx-xl-32 {
        --x-gutter-x: 8rem;
    }

    .g-xl-32,
    .gy-xl-32 {
        --x-gutter-y: 8rem;
    }

    .g-xl-40,
    .gx-xl-40 {
        --x-gutter-x: 10rem;
    }

    .g-xl-40,
    .gy-xl-40 {
        --x-gutter-y: 10rem;
    }

    .g-xl-48,
    .gx-xl-48 {
        --x-gutter-x: 12rem;
    }

    .g-xl-48,
    .gy-xl-48 {
        --x-gutter-y: 12rem;
    }

    .g-xl-56,
    .gx-xl-56 {
        --x-gutter-x: 14rem;
    }

    .g-xl-56,
    .gy-xl-56 {
        --x-gutter-y: 14rem;
    }

    .g-xl-64,
    .gx-xl-64 {
        --x-gutter-x: 16rem;
    }

    .g-xl-64,
    .gy-xl-64 {
        --x-gutter-y: 16rem;
    }

    .g-xl-72,
    .gx-xl-72 {
        --x-gutter-x: 18rem;
    }

    .g-xl-72,
    .gy-xl-72 {
        --x-gutter-y: 18rem;
    }

    .g-xl-80,
    .gx-xl-80 {
        --x-gutter-x: 20rem;
    }

    .g-xl-80,
    .gy-xl-80 {
        --x-gutter-y: 20rem;
    }

    .g-xl-88,
    .gx-xl-88 {
        --x-gutter-x: 22rem;
    }

    .g-xl-88,
    .gy-xl-88 {
        --x-gutter-y: 22rem;
    }

    .g-xl-96,
    .gx-xl-96 {
        --x-gutter-x: 24rem;
    }

    .g-xl-96,
    .gy-xl-96 {
        --x-gutter-y: 24rem;
    }

    .g-xl-px,
    .gx-xl-px {
        --x-gutter-x: 1px;
    }

    .g-xl-px,
    .gy-xl-px {
        --x-gutter-y: 1px;
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        flex: 1 0 0%;
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto;
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%;
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%;
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%;
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%;
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%;
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%;
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --x-gutter-x: 0;
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --x-gutter-y: 0;
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --x-gutter-x: 0.25rem;
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --x-gutter-y: 0.25rem;
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --x-gutter-x: 0.5rem;
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --x-gutter-y: 0.5rem;
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --x-gutter-x: 0.75rem;
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --x-gutter-y: 0.75rem;
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --x-gutter-x: 1rem;
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --x-gutter-y: 1rem;
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --x-gutter-x: 1.25rem;
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --x-gutter-y: 1.25rem;
    }

    .g-xxl-6,
    .gx-xxl-6 {
        --x-gutter-x: 1.5rem;
    }

    .g-xxl-6,
    .gy-xxl-6 {
        --x-gutter-y: 1.5rem;
    }

    .g-xxl-7,
    .gx-xxl-7 {
        --x-gutter-x: 1.75rem;
    }

    .g-xxl-7,
    .gy-xxl-7 {
        --x-gutter-y: 1.75rem;
    }

    .g-xxl-8,
    .gx-xxl-8 {
        --x-gutter-x: 2rem;
    }

    .g-xxl-8,
    .gy-xxl-8 {
        --x-gutter-y: 2rem;
    }

    .g-xxl-10,
    .gx-xxl-10 {
        --x-gutter-x: 2.5rem;
    }

    .g-xxl-10,
    .gy-xxl-10 {
        --x-gutter-y: 2.5rem;
    }

    .g-xxl-12,
    .gx-xxl-12 {
        --x-gutter-x: 3rem;
    }

    .g-xxl-12,
    .gy-xxl-12 {
        --x-gutter-y: 3rem;
    }

    .g-xxl-14,
    .gx-xxl-14 {
        --x-gutter-x: 3.5rem;
    }

    .g-xxl-14,
    .gy-xxl-14 {
        --x-gutter-y: 3.5rem;
    }

    .g-xxl-16,
    .gx-xxl-16 {
        --x-gutter-x: 4rem;
    }

    .g-xxl-16,
    .gy-xxl-16 {
        --x-gutter-y: 4rem;
    }

    .g-xxl-18,
    .gx-xxl-18 {
        --x-gutter-x: 4.5rem;
    }

    .g-xxl-18,
    .gy-xxl-18 {
        --x-gutter-y: 4.5rem;
    }

    .g-xxl-20,
    .gx-xxl-20 {
        --x-gutter-x: 5rem;
    }

    .g-xxl-20,
    .gy-xxl-20 {
        --x-gutter-y: 5rem;
    }

    .g-xxl-24,
    .gx-xxl-24 {
        --x-gutter-x: 6rem;
    }

    .g-xxl-24,
    .gy-xxl-24 {
        --x-gutter-y: 6rem;
    }

    .g-xxl-32,
    .gx-xxl-32 {
        --x-gutter-x: 8rem;
    }

    .g-xxl-32,
    .gy-xxl-32 {
        --x-gutter-y: 8rem;
    }

    .g-xxl-40,
    .gx-xxl-40 {
        --x-gutter-x: 10rem;
    }

    .g-xxl-40,
    .gy-xxl-40 {
        --x-gutter-y: 10rem;
    }

    .g-xxl-48,
    .gx-xxl-48 {
        --x-gutter-x: 12rem;
    }

    .g-xxl-48,
    .gy-xxl-48 {
        --x-gutter-y: 12rem;
    }

    .g-xxl-56,
    .gx-xxl-56 {
        --x-gutter-x: 14rem;
    }

    .g-xxl-56,
    .gy-xxl-56 {
        --x-gutter-y: 14rem;
    }

    .g-xxl-64,
    .gx-xxl-64 {
        --x-gutter-x: 16rem;
    }

    .g-xxl-64,
    .gy-xxl-64 {
        --x-gutter-y: 16rem;
    }

    .g-xxl-72,
    .gx-xxl-72 {
        --x-gutter-x: 18rem;
    }

    .g-xxl-72,
    .gy-xxl-72 {
        --x-gutter-y: 18rem;
    }

    .g-xxl-80,
    .gx-xxl-80 {
        --x-gutter-x: 20rem;
    }

    .g-xxl-80,
    .gy-xxl-80 {
        --x-gutter-y: 20rem;
    }

    .g-xxl-88,
    .gx-xxl-88 {
        --x-gutter-x: 22rem;
    }

    .g-xxl-88,
    .gy-xxl-88 {
        --x-gutter-y: 22rem;
    }

    .g-xxl-96,
    .gx-xxl-96 {
        --x-gutter-x: 24rem;
    }

    .g-xxl-96,
    .gy-xxl-96 {
        --x-gutter-y: 24rem;
    }

    .g-xxl-px,
    .gx-xxl-px {
        --x-gutter-x: 1px;
    }

    .g-xxl-px,
    .gy-xxl-px {
        --x-gutter-y: 1px;
    }
}

.lead {
    font-size: 1.125rem;
    font-weight: 400;
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-1 {
        font-size: 5rem;
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem;
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-3 {
        font-size: 4rem;
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-5 {
        font-size: 3rem;
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bolder;
    line-height: 1.3;
}

@media (min-width: 1200px) {
    .display-6 {
        font-size: 2.5rem;
    }
}

.list-inline,
.list-unstyled {
    list-style: none;
    padding-left: 0;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase;
}

.blockquote {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.blockquote> :last-child {
    margin-bottom: 0;
}

.blockquote-footer {
    color: #6b7b93;
    font-size: 0.875em;
    margin-bottom: 1rem;
    margin-top: -1rem;
}

.blockquote-footer:before {
    content: "— ";
}

.surtitle {
    color: #525f7f;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.text-highlight-primary {
    background-image: linear-gradient(transparent 66%, rgba(92, 96, 245, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-secondary {
    background-image: linear-gradient(transparent 66%, rgba(207, 214, 223, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-tertiary {
    background-image: linear-gradient(transparent 66%, rgba(255, 87, 154, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-success {
    background-image: linear-gradient(transparent 66%, rgba(0, 204, 136, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-info {
    background-image: linear-gradient(transparent 66%, rgba(0, 212, 255, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-warning {
    background-image: linear-gradient(transparent 66%, rgba(255, 140, 0, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-danger {
    background-image: linear-gradient(transparent 66%, rgba(255, 51, 102, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-light,
.text-highlight-white {
    background-image: linear-gradient(transparent 66%, hsla(0, 0%, 100%, 0.15) 0);
    background-repeat: repeat-x;
}

.text-highlight-dark {
    background-image: linear-gradient(transparent 66%, rgba(22, 25, 44, 0.15) 0);
    background-repeat: repeat-x;
}

.dropcaps>p {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}

.dropcaps>p:first-child:first-letter {
    float: left;
    font-size: 3.5em;
    font-weight: 700;
    line-height: 1;
    margin-right: 0.15em;
    margin-top: 0.05em;
}

.form-label {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.col-form-label,
.form-label {
    color: #16192c;
    font-weight: 500;
}

.col-form-label {
    font-size: inherit;
    line-height: 1.3;
    margin-bottom: 0;
    padding-bottom: calc(0.75rem + 1px);
    padding-top: calc(0.75rem + 1px);
}

.col-form-label-lg {
    font-size: 1rem;
    padding-bottom: calc(1rem + 1px);
    padding-top: calc(1rem + 1px);
}

.col-form-label-sm {
    font-size: 0.875rem;
    padding-bottom: calc(0.5rem + 1px);
    padding-top: calc(0.5rem + 1px);
}

.form-text {
    color: #6b7b93;
    font-size: 0.875em;
    margin-top: 0.25rem;
}

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #e7eaf0;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(5, 102, 234, 0.3);
    color: #16192c;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    padding: 0.75rem 1.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control[type="file"] {
    overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
}

.form-control:focus {
    background-color: #fff;
    border-color: #0566ea;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    color: #16192c;
    outline: 0;
}

.form-control::-webkit-date-and-time-value {
    height: 1.3em;
}

.form-control::-moz-placeholder {
    color: #8898a9;
    opacity: 1;
}

.form-control::placeholder {
    color: #8898a9;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e7eaf0;
    opacity: 1;
}

.form-control::file-selector-button {
    -webkit-margin-end: 1.25rem;
    background-color: #f5f9fc;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #16192c;
    margin: -0.75rem -1.25rem;
    margin-inline-end: 1.25rem;
    padding: 0.75rem 1.25rem;
    pointer-events: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none;
    }

    .form-control::file-selector-button {
        transition: none;
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e9edef;
}

.form-control::-webkit-file-upload-button {
    -webkit-margin-end: 1.25rem;
    background-color: #f5f9fc;
    border: 0 solid;
    border-color: inherit;
    border-inline-end-width: 1px;
    border-radius: 0;
    color: #16192c;
    margin: -0.75rem -1.25rem;
    margin-inline-end: 1.25rem;
    padding: 0.75rem 1.25rem;
    pointer-events: none;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e9edef;
}

.form-control-plaintext {
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    color: #525f7f;
    display: block;
    line-height: 1.3;
    margin-bottom: 0;
    padding: 0.75rem 0;
    width: 100%;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-left: 0;
    padding-right: 0;
}

.form-control-sm {
    border-radius: 0.375rem;
    font-size: 0.875rem;
    min-height: calc(1.3em + 1rem + 2px);
    padding: 0.5rem 1rem;
}

.form-control-sm::file-selector-button {
    -webkit-margin-end: 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem;
    padding: 0.5rem 1rem;
}

.form-control-sm::-webkit-file-upload-button {
    -webkit-margin-end: 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem;
    padding: 0.5rem 1rem;
}

.form-control-lg {
    border-radius: 0.5rem;
    font-size: 1rem;
    min-height: calc(1.3em + 2rem + 2px);
    padding: 1rem 1.5rem;
}

.form-control-lg::file-selector-button {
    -webkit-margin-end: 1.5rem;
    margin: -1rem -1.5rem;
    margin-inline-end: 1.5rem;
    padding: 1rem 1.5rem;
}

.form-control-lg::-webkit-file-upload-button {
    -webkit-margin-end: 1.5rem;
    margin: -1rem -1.5rem;
    margin-inline-end: 1.5rem;
    padding: 1rem 1.5rem;
}

textarea.form-control {
    min-height: calc(1.3em + 1.5rem + 2px);
}

textarea.form-control-sm {
    min-height: calc(1.3em + 1rem + 2px);
}

textarea.form-control-lg {
    min-height: calc(1.3em + 2rem + 2px);
}

.form-control-color {
    height: auto;
    padding: 0.75rem;
    width: 3rem;
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
}

.form-control-color::-moz-color-swatch {
    border-radius: 0.375rem;
    height: 1.3em;
}

.form-control-color::-webkit-color-swatch {
    border-radius: 0.375rem;
    height: 1.3em;
}

.form-select {
    -moz-padding-start: calc(1.25rem - 3px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 1.25rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: 1px solid #e7eaf0;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(5, 102, 234, 0.3);
    color: #16192c;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    padding: 0.75rem 3.75rem 0.75rem 1.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .form-select {
        transition: none;
    }
}

.form-select:focus {
    border-color: #0566ea;
    box-shadow: 0 1px 2px rgba(5, 102, 234, 0.3), 0 0 0 3px rgba(92, 96, 245, 0.25);
    outline: 0;
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
    background-image: none;
    padding-right: 1.25rem;
}

.form-select:disabled {
    background-color: #e7eaf0;
    color: #8898a9;
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #16192c;
}

.form-select-sm {
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}

.form-select-lg {
    border-radius: 0.5rem;
    font-size: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-top: 1rem;
}

.form-check {
    display: block;
    margin-bottom: 0;
    min-height: 1.625rem;
    padding-left: 1.5em;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.form-check-input {
    -webkit-print-color-adjust: exact;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    color-adjust: exact;
    height: 1em;
    margin-top: 0.3125em;
    vertical-align: top;
    width: 1em;
}

.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}

.form-check-input[type="radio"] {
    border-radius: 50%;
}

.form-check-input:active {
    filter: brightness(90%);
}

.form-check-input:focus {
    border-color: #0566ea;
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
    outline: 0;
}

.form-check-input:checked {
    background-color: #0566ea;
    border-color: #0566ea;
}

.form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23FFF'/%3E%3C/svg%3E");
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: #0566ea;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
    border-color: #0566ea;
}

.form-check-input:disabled {
    filter: none;
    opacity: 0.5;
    pointer-events: none;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    opacity: 0.5;
}

.form-switch {
    padding-left: 3.375em;
}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
    background-position: 0;
    border-radius: 2.875em;
    margin-left: -3.375em;
    transition: background-position 0.15s ease-in-out;
    width: 2.875em;
}

@media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        transition: none;
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230566ea'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
    background-position: 100%;
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}

.btn-check {
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    position: absolute;
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
    filter: none;
    opacity: 0.65;
    pointer-events: none;
}

.form-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: calc(1rem + 6px);
    padding: 0;
    width: 100%;
}

.form-range:focus {
    outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(92, 96, 245, 0.25);
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(92, 96, 245, 0.25);
}

.form-range::-moz-focus-outer {
    border: 0;
}

.form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0566ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    height: 1rem;
    margin-top: -0.25rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #cecffc;
}

.form-range::-webkit-slider-runnable-track {
    background-color: #cfd6df;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
}

.form-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    background-color: #0566ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    height: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 1rem;
}

@media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #cecffc;
}

.form-range::-moz-range-track {
    background-color: #cfd6df;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
}

.form-range:disabled {
    pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #8898a9;
}

.form-range:disabled::-moz-range-thumb {
    background-color: #8898a9;
}

.form-floating {
    position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

.form-floating>label {
    border: 1px solid transparent;
    height: 100%;
    left: 0;
    padding: 1rem 1.25rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-floating>label {
        transition: none;
    }
}

.form-floating>.form-control {
    padding: 1rem 1.25rem;
}

.form-floating>.form-control::-moz-placeholder {
    color: transparent;
}

.form-floating>.form-control::placeholder {
    color: transparent;
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-bottom: 0.625rem;
    padding-top: 1.625rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding-bottom: 0.625rem;
    padding-top: 1.625rem;
}

.form-floating>.form-control:-webkit-autofill {
    padding-bottom: 0.625rem;
    padding-top: 1.625rem;
}

.form-floating>.form-select {
    padding-bottom: 0.625rem;
    padding-top: 1.625rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 3;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.input-group .btn:focus {
    z-index: 3;
}

.input-group-text {
    align-items: center;
    background-color: #f5f9fc;
    border: 1px solid #e7eaf0;
    border-radius: 0.375rem;
    color: #6b7b93;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    padding: 0.75rem 1.25rem;
    text-align: center;
    white-space: nowrap;
}

.input-group-lg>.btn,
.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text {
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 1rem 1.5rem;
}

.input-group-sm>.btn,
.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text {
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
    padding-right: 5rem;
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
}

.valid-feedback {
    color: #28a745;
    display: none;
    font-size: 0.875em;
    margin-top: 0.25rem;
    width: 100%;
}

.valid-tooltip {
    background-color: rgba(0, 204, 136, 0.9);
    border-radius: 0.375rem;
    color: #fff;
    display: none;
    font-size: 0.875rem;
    margin-top: 0.1rem;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    position: absolute;
    top: 100%;
    z-index: 5;
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right calc(0.325em + 0.375rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
    border-color: #28a745;
    padding-right: calc(1.3em + 1.5rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
    padding-right: calc(1.3em + 1.5rem);
}

.form-select.is-valid,
.was-validated .form-select:valid {
    border-color: #28a745;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-position: right 1.25rem center, center right 3.75rem;
    background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
    padding-right: 6.875rem;
}

.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
    border-color: #28a745;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #28a745;
}

.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745;
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: 0.5em;
}

.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
    z-index: 1;
}

.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
    z-index: 3;
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: 0.875em;
    margin-top: 0.25rem;
    width: 100%;
}

.invalid-tooltip {
    background-color: rgba(255, 51, 102, 0.9);
    border-radius: 0.375rem;
    color: #fff;
    display: none;
    font-size: 0.875rem;
    margin-top: 0.1rem;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    position: absolute;
    top: 100%;
    z-index: 5;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right calc(0.325em + 0.375rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
    border-color: #dc3545;
    padding-right: calc(1.3em + 1.5rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
    padding-right: calc(1.3em + 1.5rem);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
    border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-position: right 1.25rem center, center right 3.75rem;
    background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
    padding-right: 6.875rem;
}

.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}

.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
    border-color: #dc3545;
}

.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
    background-color: #dc3545;
}

.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545;
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: 0.5em;
}

.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
    z-index: 2;
}

.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
    z-index: 3;
}

form {
    -webkit-margin-after: 0;
    margin-block-end: 0;
}

.form-control:focus::-moz-placeholder {
    color: #abb6c5;
}

.form-control:focus::placeholder {
    color: #abb6c5;
}

.form-control-muted {
    background-color: #eef0f5;
    border-color: #eef0f5;
}

.form-control-muted:focus {
    background-color: #f1f2f7;
}

.form-control-alt {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-control-alt:focus {
    background-color: #fff;
    border-color: transparent;
    box-shadow: 0 0 0 2px #0566ea;
}

.form-control-flush {
    padding: 0;
}

.form-control-flush,
.form-control-flush:focus {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
}

.form-check-label {
    color: #16192c;
    font-size: 0.875rem;
    font-weight: 500;
}

textarea[resize="none"] {
    resize: none !important;
}

textarea[resize="both"] {
    resize: both !important;
}

textarea[resize="vertical"] {
    resize: vertical !important;
}

textarea[resize="horizontal"] {
    resize: horizontal !important;
}

.textarea-autosize {
    display: grid;
}

.textarea-autosize:after {
    content: attr(data-replicated-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
}

.textarea-autosize>textarea {
    overflow: hidden;
    resize: none;
}

.textarea-autosize:after,
.textarea-autosize>textarea {
    border: 1px solid #000;
    font: inherit;
    grid-area: 1/1/2/2;
    padding: 0.5rem;
}

.form-group-stacked .form-control,
.form-group-stacked .form-select {
    box-sizing: border-box;
    height: auto;
    position: relative;
}

.form-group-stacked .form-control:focus,
.form-group-stacked .form-select:focus {
    z-index: 2;
}

.form-group-stacked> :first-child .form-control,
.form-group-stacked> :first-child .form-select {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
}

.form-group-stacked> :last-child .form-control,
.form-group-stacked> :last-child .form-select {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-group-stacked>.row {
    margin: 0;
}

.form-group-stacked>.row> :first-child,
.form-group-stacked>.row> :last-child {
    padding: 0;
}

.form-group-stacked>.row> :first-child .form-control,
.form-group-stacked>.row> :first-child .form-select {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
}

.form-group-stacked>.row> :last-child .form-control,
.form-group-stacked>.row> :last-child .form-select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.form-dark .form-control {
    background-color: #2d3041;
    border: 1px solid #2d3041;
    color: hsla(0, 0%, 100%, 0.9);
}

.form-dark .form-control::-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.8);
}

.form-dark .form-control::placeholder {
    color: hsla(0, 0%, 100%, 0.8);
}

.form-dark .form-control:focus {
    background-color: #222537;
    border: 1px solid #222537;
}

.form-dark .form-control:focus::-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.6);
}

.form-dark .form-control:focus::placeholder {
    color: hsla(0, 0%, 100%, 0.6);
}

.form-dark .input-group .input-group-text {
    background-color: #2d3041;
    border-color: #2d3041;
    color: hsla(0, 0%, 100%, 0.9);
}

.form-dark .input-group:focus-within .input-group-text {
    background-color: #222537;
    border-color: #222537;
}

.form-stacked .form-control {
    box-sizing: border-box;
    position: relative;
}

.form-stacked .form-control:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
}

.form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
    margin-bottom: -1px;
}

.form-stacked .form-control:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-stacked .form-control:focus {
    z-index: 2;
}

.input-group {
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(5, 102, 234, 0.3);
}

.input-group .form-control {
    box-shadow: none;
}

.input-group:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.input-group:focus-within .input-group-text {
    border-color: #0566ea;
}

.input-group:focus-within .form-control {
    border-color: #0566ea;
    box-shadow: none;
}

.input-group-text {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-flush .form-control,
.input-group-flush .input-group-text {
    border: 0 !important;
}

.input-group-inline {
    position: relative;
}

.input-group-inline,
.input-group-inline.input-group-sm {
    border-radius: 0.375rem;
}

.input-group-inline.input-group-lg {
    border-radius: 0.5rem;
}

.input-group-inline .input-group-text {
    background: #fff;
}

.input-group-inline> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-left: 0;
    margin-left: 0 !important;
    padding-left: 0.25rem;
}

.input-group-inline> :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-right: 0;
    margin-right: 0 !important;
    padding-right: 0.25rem;
}

.form-switch {
    min-height: 1.5rem;
}

.form-switch>.form-check-input {
    height: 1.5rem;
    margin-top: 0;
}

.form-switch>.form-check-label {
    margin-top: calc(0.75rem - 0.8125em);
}

.form-check-linethrough .form-check-input:checked+.form-check-label {
    text-decoration: line-through;
}

.form-item-check {
    clip: rect(0, 0, 0, 0);
    display: none;
    pointer-events: none;
    position: absolute;
}

.form-item-check:checked+.form-item .form-item-click,
.form-item-check:hover+.form-item .form-item-click {
    border-color: #0566ea;
}

.form-item-check:disabled+.form-item .form-item-click,
.form-item-check[disabled]+.form-item .form-item-click {
    filter: none;
    opacity: 0.5;
    pointer-events: none;
}

.form-color {
    list-style: none;
    padding: 0;
}

.form-color:after,
.form-color:before {
    content: "";
    display: table;
}

.form-color:after {
    clear: both;
}

.form-color label {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    display: flex;
    float: left;
    height: 2.25rem;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 0.375rem;
    opacity: 0.7;
    padding: 0.375rem;
    transform: scale(0.95);
    transition: all 0.2s ease-in-out;
    width: 2.25rem;
}

@media (prefers-reduced-motion: reduce) {
    .form-color label {
        transition: none;
    }
}

.form-color label:hover {
    cursor: pointer;
    opacity: 1;
}

.form-color label i,
.form-color label svg {
    color: hsla(0, 0%, 100%, 0.9);
    opacity: 0;
}

.form-color input {
    left: -9999px;
    position: absolute;
}

.form-color input:checked+label {
    opacity: 1;
    transform: scale(1.1);
}

.form-color input:checked+label i,
.form-color input:checked+label svg {
    opacity: 1;
}

.form-color-wide label {
    height: 1.5rem;
}

.form-color-xl label {
    height: 5rem;
    width: 5rem;
}

.form-color-xl.form-color-wide label {
    height: 3.5rem;
    width: 5rem;
}

.form-color-lg label {
    height: 3.25rem;
    width: 3.25rem;
}

.form-color-sm label {
    height: 1.25rem;
    width: 1.25rem;
}

.input-group.is-valid .form-control,
.input-group.is-valid .form-select,
.input-group.is-valid .input-group-text,
.was-validated .input-group:valid .form-control,
.was-validated .input-group:valid .form-select,
.was-validated .input-group:valid .input-group-text {
    border-color: #28a745;
}

.input-group.is-valid:focus-within,
.was-validated .input-group:valid:focus-within {
    border-color: #28a745;
    box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}

.input-group.is-invalid .form-control,
.input-group.is-invalid .form-select,
.input-group.is-invalid .input-group-text,
.was-validated .input-group:invalid .form-control,
.was-validated .input-group:invalid .form-select,
.was-validated .input-group:invalid .input-group-text {
    border-color: #dc3545;
}

.input-group.is-invalid:focus-within,
.was-validated .input-group:invalid:focus-within {
    border-color: #dc3545;
    box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}

.accordion-button {
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #525f7f;
    display: flex;
    font-size: 1rem;
    overflow-anchor: none;
    padding: 1.5rem 1.25rem;
    position: relative;
    text-align: left;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none;
    }
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: inset 0 -1px 0 #e7eaf0;
    color: #16192c;
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2316192C'%3E%3Cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
    transform: rotate(180deg);
}

.accordion-button:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23525F7F'%3E%3Cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    content: "";
    flex-shrink: 0;
    height: 1.25rem;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
    width: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button:after {
        transition: none;
    }
}

.accordion-button:hover {
    z-index: 2;
}

.accordion-button:focus {
    border-color: #e7eaf0;
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
    outline: 0;
    z-index: 3;
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    background-color: transparent;
    border: 1px solid #e7eaf0;
}

.accordion-item:first-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item:last-of-type {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: calc(0.375rem - 1px);
    border-bottom-right-radius: calc(0.375rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.accordion-body {
    padding: 1.5rem 1.25rem;
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}

.accordion-card .card-header {
    cursor: pointer;
    position: relative;
}

.accordion-card .card-header:after {
    content: "+";
    font-family: var(--x-font-sans-serif);
    font-weight: 700;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.accordion-card .card-header[aria-expanded="false"]:after {
    content: "+";
}

.accordion-card .card-header[aria-expanded="true"]:after {
    content: "-";
}

.accordion-card .card-header[aria-expanded="true"] .heading {
    color: theme-color("primary");
}

.accordion-spaced>.card {
    border-radius: 0.75rem !important;
}

.accordion-spaced>.card:not(:last-of-type) {
    border: 1px solid #eceef3;
    margin-bottom: 1.5rem;
}

.alert {
    border: 1px solid transparent;
    border-radius: 0.375rem;
    margin-bottom: 0;
    padding: 0.875rem 1rem;
    position: relative;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 600;
}

.alert-dismissible {
    padding-right: 3rem;
}

.alert-dismissible .btn-close {
    padding: 1.09375rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.alert-primary {
    background-color: #efeffe;
    border-color: #dedffd;
    color: #5356dd;
}

.alert-primary .alert-link {
    color: #4245b1;
}

.alert-secondary {
    background-color: #fafbfc;
    border-color: #f5f7f9;
    color: #151516;
}

.alert-secondary .alert-link {
    color: #111112;
}

.alert-tertiary {
    background-color: #ffeef5;
    border-color: #ffddeb;
    color: #e64e8b;
}

.alert-tertiary .alert-link {
    color: #b83e6f;
}

.alert-success {
    background-color: #e6faf3;
    border-color: #ccf5e7;
    color: #00b87a;
}

.alert-success .alert-link {
    color: #009362;
}

.alert-info {
    background-color: #e6fbff;
    border-color: #ccf6ff;
    color: #00bfe6;
}

.alert-info .alert-link {
    color: #0099b8;
}

.alert-warning {
    background-color: #fff4e6;
    border-color: #ffe8cc;
    color: #e67e00;
}

.alert-warning .alert-link {
    color: #b86500;
}

.alert-danger {
    background-color: #ffebf0;
    border-color: #ffd6e0;
    color: #e62e5c;
}

.alert-danger .alert-link {
    color: #b8254a;
}

.alert-white {
    background-color: #fff;
    border-color: #fff;
    color: #1a1a1a;
}

.alert-white .alert-link {
    color: #151515;
}

.alert-light {
    background-color: #fff;
    border-color: #fff;
    color: #1a1a1a;
}

.alert-light .alert-link {
    color: #151515;
}

.alert-dark {
    background-color: #e8e8ea;
    border-color: #d0d1d5;
    color: #141728;
}

.alert-dark .alert-link {
    color: #101220;
}

.avatar {
    align-items: center;
    border-radius: 0.375rem;
    display: inline-flex;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875rem;
    justify-content: center;
    position: relative;
    vertical-align: middle;
    width: 2.875rem;
}

.avatar img {
    border-radius: inherit;
    width: 100%;
}

.avatar+.avatar {
    margin-left: 0.25rem;
}

.avatar+.avatar-content {
    display: inline-block;
    margin-left: 0.75rem;
}

.avatar-2xl {
    font-size: calc(1.375rem + 1.5vw);
    height: 7.5rem;
    width: 7.5rem;
}

@media (min-width: 1200px) {
    .avatar-2xl {
        font-size: 2.5rem;
    }
}

.avatar-xl {
    font-size: calc(1.2625rem + 0.15vw);
    height: 6rem;
    width: 6rem;
}

@media (min-width: 1200px) {
    .avatar-xl {
        font-size: 1.375rem;
    }
}

.avatar-lg {
    font-size: 1.25rem;
    height: 3.5rem;
    width: 3.5rem;
}

.avatar-sm {
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 2.25rem;
    width: 2.25rem;
}

.avatar-xs {
    border-radius: 0.25rem;
    font-size: 0.675rem;
    height: 1.75rem;
    width: 1.75rem;
}

.avatar-group {
    display: inline-block;
    line-height: 1;
}

.avatar-group .avatar {
    transition: margin 0.15s ease-in-out;
    z-index: 1;
}

@media (prefers-reduced-motion: reduce) {
    .avatar-group .avatar {
        transition: none;
    }
}

.avatar-group .avatar:hover {
    z-index: 2;
}

.avatar-group .avatar+.avatar {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1.25rem;
}

.avatar-group .avatar-sm+.avatar-sm,
.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -1rem;
}

.avatar-group:hover .avatar {
    border-bottom-left-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.avatar-group:hover .avatar-sm {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.avatar-ungroup-hover:hover .avatar:not(:first-child) {
    margin-left: 0;
}

.badge {
    border-radius: 0.375rem;
    color: #fff;
    display: inline-block;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    padding: 0.2rem 0.6rem;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge {
    line-height: 1rem;
}

.badge-xs {
    font-size: 60%;
    padding: 0.1rem 0.5rem;
}

.badge-md {
    padding: 0.4rem 1rem;
}

.badge-lg {
    font-size: 1em;
    padding: 0.6rem 1rem;
}

.badge-count {
    align-items: center;
    display: inline-flex;
    font-size: 0.75rem;
    font-weight: 600;
    height: 1.25rem;
    justify-content: center;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 1.25rem;
}

.badge-count.badge-xs {
    font-size: 0.5rem;
    height: 1rem;
    width: 1rem;
}

.badge-count.badge-md {
    height: 1.5rem;
    width: 1.5rem;
}

.badge-count.badge-lg {
    height: 2rem;
    width: 2rem;
}

.badge-count svg {
    margin: 0;
}

.badge-dot {
    align-items: center;
    background: transparent;
    color: #525f7f;
    display: inline-flex;
    font-weight: 400;
    padding: 0;
}

.badge-dot i {
    border-radius: 50%;
    display: inline-block;
    height: 0.375rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    width: 0.375rem;
}

.badge-dot.badge-md i {
    height: 0.5rem;
    width: 0.5rem;
}

.badge-dot.badge-lg i {
    height: 0.625rem;
    width: 0.625rem;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.375rem;
}

.breadcrumb-item+.breadcrumb-item:before {
    color: #abb6c5;
    content: var(--x-breadcrumb-divider, "/");
    float: left;
    padding-right: 0.375rem;
}

.breadcrumb-item.active {
    color: #16192c;
}

.btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    color: #525f7f;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3;
    padding: 0.75rem 1.25rem;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #525f7f;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
    outline: 0;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn:active {
    box-shadow: 0 0 0 0 transparent;
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn:active:focus {
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25), 0 0 0 0 transparent;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none;
}

.btn-primary {
    background-color: #0566ea;
    border-color: #0566ea;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #0b56bc;
    border-color: #0b56bc;
    color: #fff;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    background-color: #0b56bc;
    border-color: #0b56bc;
    color: #fff;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #0566ea;
    border-color: #0566ea;
    color: #fff;
}

.btn-secondary {
    background-color: #cfd6df;
    border-color: #cfd6df;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #000;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover {
    background-color: #d9dee5;
    border-color: #d4dae2;
    color: #000;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(176, 182, 190, 0.5);
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #d9dee5;
    border-color: #d4dae2;
    color: #000;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(176, 182, 190, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    background-color: #cfd6df;
    border-color: #cfd6df;
    color: #000;
}

.btn-tertiary {
    background-color: #ff579a;
    border-color: #ff579a;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-tertiary,
.btn-tertiary:focus,
.btn-tertiary:hover {
    background-color: #cc467b;
    border-color: #cc467b;
    color: #fff;
}

.btn-check:focus+.btn-tertiary,
.btn-tertiary:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 112, 169, 0.5);
}

.btn-check:active+.btn-tertiary,
.btn-check:checked+.btn-tertiary,
.btn-tertiary.active,
.btn-tertiary:active,
.show>.btn-tertiary.dropdown-toggle {
    background-color: #cc467b;
    border-color: #bf4174;
    color: #fff;
}

.btn-check:active+.btn-tertiary:focus,
.btn-check:checked+.btn-tertiary:focus,
.btn-tertiary.active:focus,
.btn-tertiary:active:focus,
.show>.btn-tertiary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 112, 169, 0.5);
}

.btn-tertiary.disabled,
.btn-tertiary:disabled {
    background-color: #ff579a;
    border-color: #ff579a;
    color: #fff;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-success,
.btn-success:focus,
.btn-success:hover {
    background-color: #218838;
    border-color: #218838;
    color: #fff;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(38, 212, 154, 0.5);
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    background-color: #218838;
    border-color: #096;
    color: #fff;
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(38, 212, 154, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
}

.btn-info {
    background-color: #00d4ff;
    border-color: #00d4ff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #000;
}

.btn-check:focus+.btn-info,
.btn-info:focus,
.btn-info:hover {
    background-color: #3df;
    border-color: #1ad8ff;
    color: #000;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 180, 217, 0.5);
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info:active,
.show>.btn-info.dropdown-toggle {
    background-color: #3df;
    border-color: #1ad8ff;
    color: #000;
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 180, 217, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    background-color: #00d4ff;
    border-color: #00d4ff;
    color: #000;
}

.btn-warning {
    background-color: #ff8c00;
    border-color: #ff8c00;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus,
.btn-warning:hover {
    background-color: #cc7000;
    border-color: #cc7000;
    color: #fff;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 157, 38, 0.5);
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning:active,
.show>.btn-warning.dropdown-toggle {
    background-color: #cc7000;
    border-color: #bf6900;
    color: #fff;
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 157, 38, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    background-color: #ff8c00;
    border-color: #ff8c00;
    color: #fff;
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus,
.btn-danger:hover {
    background-color: #bd2130;
    border-color: #bd2130;
    color: #fff;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 82, 125, 0.5);
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger:active,
.show>.btn-danger.dropdown-toggle {
    background-color: #bd2130;
    border-color: #bf264d;
    color: #fff;
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 82, 125, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.btn-white {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus+.btn-white,
.btn-white,
.btn-white:focus,
.btn-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:focus+.btn-white,
.btn-white:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}

.btn-check:active+.btn-white,
.btn-check:checked+.btn-white,
.btn-white.active,
.btn-white:active,
.show>.btn-white.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:active+.btn-white:focus,
.btn-check:checked+.btn-white:focus,
.btn-white.active:focus,
.btn-white:active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}

.btn-light,
.btn-white.disabled,
.btn-white:disabled {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-light {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-check:focus+.btn-light,
.btn-light:focus,
.btn-light:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light:active,
.show>.btn-light.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 85%, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-dark {
    background-color: #16192c;
    border-color: #16192c;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #fff;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus,
.btn-dark:hover {
    background-color: #121423;
    border-color: #121423;
    color: #fff;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(57, 60, 76, 0.5);
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark:active,
.show>.btn-dark.dropdown-toggle {
    background-color: #121423;
    border-color: #111321;
    color: #fff;
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(57, 60, 76, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    background-color: #16192c;
    border-color: #16192c;
    color: #fff;
}

.btn-outline-primary {
    border-color: #0566ea;
    color: #0566ea;
}

.btn-outline-primary:hover {
    background-color: #0566ea;
    border-color: #0566ea;
    color: #fff;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.5);
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
    background-color: #0566ea;
    border-color: #0566ea;
    color: #fff;
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(92, 96, 245, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    background-color: transparent;
    color: #0566ea;
}

.btn-outline-secondary {
    border-color: #cfd6df;
    color: #cfd6df;
}

.btn-outline-secondary:hover {
    background-color: #cfd6df;
    border-color: #cfd6df;
    color: #000;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 3px rgba(207, 214, 223, 0.5);
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    background-color: #cfd6df;
    border-color: #cfd6df;
    color: #000;
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(207, 214, 223, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    background-color: transparent;
    color: #cfd6df;
}

.btn-outline-tertiary {
    border-color: #ff579a;
    color: #ff579a;
}

.btn-outline-tertiary:hover {
    background-color: #ff579a;
    border-color: #ff579a;
    color: #fff;
}

.btn-check:focus+.btn-outline-tertiary,
.btn-outline-tertiary:focus {
    box-shadow: 0 0 0 3px rgba(255, 87, 154, 0.5);
}

.btn-check:active+.btn-outline-tertiary,
.btn-check:checked+.btn-outline-tertiary,
.btn-outline-tertiary.active,
.btn-outline-tertiary.dropdown-toggle.show,
.btn-outline-tertiary:active {
    background-color: #ff579a;
    border-color: #ff579a;
    color: #fff;
}

.btn-check:active+.btn-outline-tertiary:focus,
.btn-check:checked+.btn-outline-tertiary:focus,
.btn-outline-tertiary.active:focus,
.btn-outline-tertiary.dropdown-toggle.show:focus,
.btn-outline-tertiary:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 87, 154, 0.5);
}

.btn-outline-tertiary.disabled,
.btn-outline-tertiary:disabled {
    background-color: transparent;
    color: #ff579a;
}

.btn-outline-success {
    border-color: #28a745;
    color: #28a745;
}

.btn-outline-success:hover {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.5);
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 204, 136, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    background-color: transparent;
    color: #28a745;
}

.btn-outline-info {
    border-color: #00d4ff;
    color: #00d4ff;
}

.btn-outline-info:hover {
    background-color: #00d4ff;
    border-color: #00d4ff;
    color: #000;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 3px rgba(0, 212, 255, 0.5);
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
    background-color: #00d4ff;
    border-color: #00d4ff;
    color: #000;
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(0, 212, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    background-color: transparent;
    color: #00d4ff;
}

.btn-outline-warning {
    border-color: #ff8c00;
    color: #ff8c00;
}

.btn-outline-warning:hover {
    background-color: #ff8c00;
    border-color: #ff8c00;
    color: #fff;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 3px rgba(255, 140, 0, 0.5);
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
    background-color: #ff8c00;
    border-color: #ff8c00;
    color: #fff;
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 140, 0, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    background-color: transparent;
    color: #ff8c00;
}

.btn-outline-danger {
    border-color: #dc3545;
    color: #dc3545;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.5);
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(255, 51, 102, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    background-color: transparent;
    color: #dc3545;
}

.btn-outline-white {
    border-color: #fff;
    color: #fff;
}

.btn-outline-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:focus+.btn-outline-white,
.btn-outline-white:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}

.btn-check:active+.btn-outline-white,
.btn-check:checked+.btn-outline-white,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show,
.btn-outline-white:active {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:active+.btn-outline-white:focus,
.btn-check:checked+.btn-outline-white:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus,
.btn-outline-white:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}

.btn-outline-white.disabled,
.btn-outline-white:disabled {
    background-color: transparent;
    color: #fff;
}

.btn-outline-light {
    border-color: #fff;
    color: #fff;
}

.btn-outline-light:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px hsla(0, 0%, 100%, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    background-color: transparent;
    color: #fff;
}

.btn-outline-dark {
    border-color: #16192c;
    color: #16192c;
}

.btn-outline-dark:hover {
    background-color: #16192c;
    border-color: #16192c;
    color: #fff;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 3px rgba(22, 25, 44, 0.5);
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
    background-color: #16192c;
    border-color: #16192c;
    color: #fff;
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(22, 25, 44, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    background-color: transparent;
    color: #16192c;
}

.btn-link {
    color: #0566ea;
    font-weight: 400;
    text-decoration: none;
}

.btn-link:hover {
    color: #4e52d0;
}

.btn-link:focus,
.btn-link:hover {
    text-decoration: none;
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6b7b93;
}

.btn-group-lg>.btn,
.btn-lg {
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 1rem 1.5rem;
}

.btn-group-sm>.btn,
.btn-sm {
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
}

.btn-neutral {
    background-color: #fff;
    border-color: #e7eaf0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #16192c;
}

.btn-check:focus+.btn-neutral,
.btn-neutral:focus,
.btn-neutral:hover {
    background-color: #e7eaf0;
    border-color: #e7eaf0;
    color: #16192c;
}

.btn-check:focus+.btn-neutral,
.btn-neutral:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(200, 203, 211, 0.5);
}

.btn-check:active+.btn-neutral,
.btn-check:checked+.btn-neutral,
.btn-neutral.active,
.btn-neutral:active,
.show>.btn-neutral.dropdown-toggle {
    background-color: #fff;
    border-color: #e9ecf2;
    color: #000;
}

.btn-check:active+.btn-neutral:focus,
.btn-check:checked+.btn-neutral:focus,
.btn-neutral.active:focus,
.btn-neutral:active:focus,
.show>.btn-neutral.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent, 0 0 0 3px rgba(200, 203, 211, 0.5);
}

.btn-neutral.disabled,
.btn-neutral:disabled {
    background-color: #fff;
    border-color: #e7eaf0;
    color: #000;
}

.btn-square {
    align-items: center;
    display: inline-flex;
    height: 2.875rem;
    justify-content: center;
    padding: 0;
    width: 2.875rem;
}

.btn-square.btn-xs {
    height: 1.75rem;
    width: 1.75rem;
}

.btn-group-sm>.btn-square.btn,
.btn-square.btn-sm {
    height: 2.25rem;
    width: 2.25rem;
}

.btn-group-lg>.btn-square.btn,
.btn-square.btn-lg {
    height: 3.5rem;
    width: 3.5rem;
}

.btn-square.btn-xl {
    height: 6rem;
    width: 6rem;
}

.btn-square.btn-2xl {
    height: 7.5rem;
    width: 7.5rem;
}

.btn-app {
    background: #000;
    color: #fff;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
    position: relative;
    text-align: left;
}

.btn-app i,
.btn-app svg {
    font-size: 2.5rem;
    left: 1.25rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
}

.btn-app .btn-text {
    display: block;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0.125rem;
}

.btn-app .btn-brand {
    display: block;
    font-size: 21px;
    line-height: 21px;
    text-align: left;
}

.btn-app:hover {
    background-color: #5659e4;
    color: #fff;
}

.btn-animated {
    overflow: hidden;
}

.btn-animated,
.btn-animated .btn-inner-visible {
    position: relative;
}

.btn-animated .btn-inner-hidden {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.btn-animated-x .btn-inner-visible {
    right: 0;
    transition: right 0.3s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner-visible {
        transition: none;
    }
}

.btn-animated-x .btn-inner-hidden {
    right: -100%;
    transition: right 0.3s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .btn-animated-x .btn-inner-hidden {
        transition: none;
    }
}

.btn-animated-x:hover .btn-inner-hidden {
    right: 0;
}

.btn-animated-x:hover .btn-inner-visible {
    right: 150%;
}

.btn-animated-y .btn-inner-visible {
    top: 0;
    transition: top 0.3s ease 0s;
}

@media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner-visible {
        transition: none;
    }
}

.btn-animated-y .btn-inner-hidden {
    left: 0;
    top: -100%;
    transition: top 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
    .btn-animated-y .btn-inner-hidden {
        transition: none;
    }
}

.btn-animated-y:hover .btn-inner-hidden {
    top: 50%;
}

.btn-animated-y:hover .btn-inner-visible {
    top: 100px;
}

.btn-group,
.btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: 1 1 auto;
    position: relative;
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.dropdown-toggle-split {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}

.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
    margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
    margin-right: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}

.btn-group.show .dropdown-toggle {
    box-shadow: 0 0 0 0 transparent;
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none;
}

.btn-group-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carousel-inner:after {
    clear: both;
    content: "";
    display: block;
}

.carousel-item {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
    float: left;
    margin-right: -100%;
    position: relative;
    transition: transform 0.6s ease-in-out;
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block;
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transform: none;
    transition-property: opacity;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    opacity: 1;
    z-index: 1;
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
    opacity: 0;
    transition: opacity 0s 0.6s;
    z-index: 0;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-end,
    .carousel-fade .active.carousel-item-start {
        transition: none;
    }
}

.carousel-control-next,
.carousel-control-prev {
    align-items: center;
    background: none;
    border: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity 0.15s ease;
    width: 15%;
    z-index: 1;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none;
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    opacity: 0.9;
    outline: 0;
    text-decoration: none;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    list-style: none;
    margin-bottom: 1rem;
    margin-left: 15%;
    margin-right: 15%;
    padding: 0;
    position: absolute;
    right: 0;
    z-index: 2;
}

.carousel-indicators [data-bs-target] {
    background-clip: padding-box;
    background-color: #fff;
    border: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    flex: 0 1 auto;
    height: 3px;
    margin-left: 3px;
    margin-right: 3px;
    opacity: 0.5;
    padding: 0;
    text-indent: -999px;
    transition: opacity 0.6s ease;
    width: 30px;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    bottom: 1.25rem;
    color: #fff;
    left: 15%;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    position: absolute;
    right: 15%;
    text-align: center;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
}

.carousel-dark .carousel-caption {
    color: #000;
}

.btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    box-sizing: content-box;
    color: currentColor;
    height: 1em;
    opacity: 0.5;
    padding: 0.25em;
    width: 1em;
}

.btn-close:hover {
    color: currentColor;
    opacity: 0.75;
    text-decoration: none;
}

.btn-close:focus {
    box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
    opacity: 1;
    outline: 0;
}

.btn-close.disabled,
.btn-close:disabled {
    opacity: 0.25;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.dropdown,
.dropend,
.dropstart,
.dropup {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle:after {
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}

.dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropdown-menu {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #f0f2f6;
    border-radius: 0.75rem;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    color: #525f7f;
    display: none;
    font-size: 0.875rem;
    list-style: none;
    margin: 0;
    min-width: 12rem;
    padding: 0.5rem 0;
    position: absolute;
    text-align: left;
    z-index: 1000;
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: 0.125rem;
    top: 100%;
}

.dropdown-menu-start {
    --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
    left: 0;
    right: auto;
}

.dropdown-menu-end {
    --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
    left: auto;
    right: 0;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position: start;
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu-sm-end {
        --bs-position: end;
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        left: auto;
        right: 0;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start;
    }

    .dropdown-menu-md-start[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu-md-end {
        --bs-position: end;
    }

    .dropdown-menu-md-end[data-bs-popper] {
        left: auto;
        right: 0;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start;
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu-lg-end {
        --bs-position: end;
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        left: auto;
        right: 0;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start;
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu-xl-end {
        --bs-position: end;
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        left: auto;
        right: 0;
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position: start;
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        left: 0;
        right: auto;
    }

    .dropdown-menu-xxl-end {
        --bs-position: end;
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        left: auto;
        right: 0;
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    bottom: 100%;
    margin-bottom: 0.125rem;
    margin-top: 0;
    top: auto;
}

.dropup .dropdown-toggle:after {
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0;
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}

.dropup .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
    left: 100%;
    margin-left: 0.125rem;
    margin-top: 0;
    right: auto;
    top: 0;
}

.dropend .dropdown-toggle:after {
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    border-right: 0;
    border-top: 0.3em solid transparent;
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}

.dropend .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropend .dropdown-toggle:after {
    vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
    left: auto;
    margin-right: 0.125rem;
    margin-top: 0;
    right: 100%;
    top: 0;
}

.dropstart .dropdown-toggle:after {
    content: "";
    display: inline-block;
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:before {
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-top: 0.3em solid transparent;
    content: "";
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:empty:after {
    margin-left: 0;
}

.dropstart .dropdown-toggle:before {
    vertical-align: 0;
}

.dropdown-divider {
    border-top: 1px solid #f2f3f7;
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
}

.dropdown-item {
    background-color: transparent;
    border: 0;
    clear: both;
    color: #16192c;
    display: block;
    font-weight: 400;
    padding: 0.5rem 1rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
    color: theme-color("primary");
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    background-color: transparent;
    color: #8898a9;
    pointer-events: none;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    color: #6b7b93;
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0;
    padding: 0.5rem 1rem;
    white-space: nowrap;
}

.dropdown-item-text {
    color: #16192c;
    display: block;
    padding: 0.5rem 1rem;
}

.dropdown-menu-dark {
    background-color: #2d3748;
    border-color: #f0f2f6;
    color: #cfd6df;
}

.dropdown-menu-dark .dropdown-item {
    color: #cfd6df;
}

.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
    background-color: hsla(0, 0%, 100%, 0.15);
    color: #fff;
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    background-color: transparent;
    color: theme-color("primary");
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #8898a9;
}

.dropdown-menu-dark .dropdown-divider {
    border-color: #f2f3f7;
}

.dropdown-menu-dark .dropdown-item-text {
    color: #cfd6df;
}

.dropdown-menu-dark .dropdown-header {
    color: #8898a9;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    display: inline-block;
}

.dropdown-heading {
    color: #16192c;
    font-size: 1rem;
    font-weight: 500;
}

.dropdown-helper {
    color: #6b7b93;
}

.dropdown-group {
    display: block;
}

.dropdown-group:hover .dropdown-heading {
    color: #0566ea;
}

.dropdown-img-left {
    align-items: center;
    background: no-repeat 50% / cover;
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 200px;
    padding: 1.625rem 1.75rem;
    position: relative;
}

.dropdown-img-left.dropdown-img-mask:before {
    background-color: rgba(92, 96, 245, 0.7);
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.dropdown-secondary {
    background-color: #f5f9fc;
}

.dropdown-body-left {
    border-bottom-left-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
}

.dropdown-body-right {
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.dropdown-menu-fluid {
    left: var(--x-gutter-x, 1.5rem) !important;
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
}

.dropdown-menu-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
    .dropdown-menu-sm-fluid {
        left: var(--x-gutter-x, 1.5rem) !important;
        width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    }

    .dropdown-menu-sm-fluid[data-bs-popper] {
        left: var(--x-gutter-x, 1.5rem);
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-fluid {
        left: var(--x-gutter-x, 1.5rem) !important;
        width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    }

    .dropdown-menu-md-fluid[data-bs-popper] {
        left: var(--x-gutter-x, 1.5rem);
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-fluid {
        left: var(--x-gutter-x, 1.5rem) !important;
        width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    }

    .dropdown-menu-lg-fluid[data-bs-popper] {
        left: var(--x-gutter-x, 1.5rem);
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-fluid {
        left: var(--x-gutter-x, 1.5rem) !important;
        width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    }

    .dropdown-menu-xl-fluid[data-bs-popper] {
        left: var(--x-gutter-x, 1.5rem);
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-fluid {
        left: var(--x-gutter-x, 1.5rem) !important;
        width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    }

    .dropdown-menu-xxl-fluid[data-bs-popper] {
        left: var(--x-gutter-x, 1.5rem);
    }
}

.dropdown-submenu .dropdown-menu {
    margin-left: 1rem;
    min-width: 16rem;
}

.dropdown-submenu .dropdown-menu:before {
    border-left: 1.5rem solid transparent;
    content: "";
    height: 100%;
    left: -1rem;
    position: absolute;
    top: 0;
}

.dropdown-body {
    padding: 2rem 2.5rem;
}

.delimiter {
    border-right: 1px solid #e7eaf0;
}

@media (min-width: 768px) {
    .dropdown-menu-sm {
        min-width: 10rem !important;
    }

    .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
        left: auto;
        right: 2rem;
    }

    .dropdown-menu-md {
        min-width: 25rem !important;
    }

    .dropdown-menu-lg {
        min-width: 30rem !important;
    }

    .dropdown-menu-xl {
        min-width: 40rem !important;
    }

    .dropdown-menu-2xl {
        min-width: 50rem !important;
    }

    .dropdown-menu-centered {
        left: 50% !important;
        transform: translateX(-50%) translateY(-3px) scale(0.96);
    }
}

.dropdown-animate>.dropdown-menu {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    -webkit-animation: show-dropdown 0.3s ease forwards;
    animation: show-dropdown 0.3s ease forwards;
    pointer-events: auto;
}

.dropdown-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    -webkit-animation: hide-dropdown 0.3s ease backwards;
    animation: hide-dropdown 0.3s ease backwards;
    display: block;
}

.dropdown-animate[data-toggle="hover"]>.dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .dropdown-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

.dropdown-animate[data-toggle="hover"]:hover>.dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0) translateY(-6px) scale(1);
    visibility: visible;
}

@media (min-width: 576px) {
    .dropdown-sm-animate>.dropdown-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .dropdown-sm-animate:not([data-toggle="hover"]) .dropdown-menu.show {
        -webkit-animation: show-dropdown 0.3s ease forwards;
        animation: show-dropdown 0.3s ease forwards;
        pointer-events: auto;
    }

    .dropdown-sm-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
        -webkit-animation: hide-dropdown 0.3s ease backwards;
        animation: hide-dropdown 0.3s ease backwards;
        display: block;
    }

    .dropdown-sm-animate[data-toggle="hover"]>.dropdown-menu {
        display: block;
        margin: 0;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .dropdown-sm-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

@media (min-width: 576px) {
    .dropdown-sm-animate[data-toggle="hover"]:hover>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0) translateY(-6px) scale(1);
        visibility: visible;
    }
}

@media (min-width: 768px) {
    .dropdown-md-animate>.dropdown-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .dropdown-md-animate:not([data-toggle="hover"]) .dropdown-menu.show {
        -webkit-animation: show-dropdown 0.3s ease forwards;
        animation: show-dropdown 0.3s ease forwards;
        pointer-events: auto;
    }

    .dropdown-md-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
        -webkit-animation: hide-dropdown 0.3s ease backwards;
        animation: hide-dropdown 0.3s ease backwards;
        display: block;
    }

    .dropdown-md-animate[data-toggle="hover"]>.dropdown-menu {
        display: block;
        margin: 0;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .dropdown-md-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

@media (min-width: 768px) {
    .dropdown-md-animate[data-toggle="hover"]:hover>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0) translateY(-6px) scale(1);
        visibility: visible;
    }
}

@media (min-width: 992px) {
    .dropdown-lg-animate>.dropdown-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .dropdown-lg-animate:not([data-toggle="hover"]) .dropdown-menu.show {
        -webkit-animation: show-dropdown 0.3s ease forwards;
        animation: show-dropdown 0.3s ease forwards;
        pointer-events: auto;
    }

    .dropdown-lg-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
        -webkit-animation: hide-dropdown 0.3s ease backwards;
        animation: hide-dropdown 0.3s ease backwards;
        display: block;
    }

    .dropdown-lg-animate[data-toggle="hover"]>.dropdown-menu {
        display: block;
        margin: 0;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .dropdown-lg-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

@media (min-width: 992px) {
    .dropdown-lg-animate[data-toggle="hover"]:hover>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0) translateY(-6px) scale(1);
        visibility: visible;
    }
}

@media (min-width: 1200px) {
    .dropdown-xl-animate>.dropdown-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .dropdown-xl-animate:not([data-toggle="hover"]) .dropdown-menu.show {
        -webkit-animation: show-dropdown 0.3s ease forwards;
        animation: show-dropdown 0.3s ease forwards;
        pointer-events: auto;
    }

    .dropdown-xl-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
        -webkit-animation: hide-dropdown 0.3s ease backwards;
        animation: hide-dropdown 0.3s ease backwards;
        display: block;
    }

    .dropdown-xl-animate[data-toggle="hover"]>.dropdown-menu {
        display: block;
        margin: 0;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .dropdown-xl-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

@media (min-width: 1200px) {
    .dropdown-xl-animate[data-toggle="hover"]:hover>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0) translateY(-6px) scale(1);
        visibility: visible;
    }
}

@media (min-width: 1400px) {
    .dropdown-xxl-animate>.dropdown-menu {
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    .dropdown-xxl-animate:not([data-toggle="hover"]) .dropdown-menu.show {
        -webkit-animation: show-dropdown 0.3s ease forwards;
        animation: show-dropdown 0.3s ease forwards;
        pointer-events: auto;
    }

    .dropdown-xxl-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
        -webkit-animation: hide-dropdown 0.3s ease backwards;
        animation: hide-dropdown 0.3s ease backwards;
        display: block;
    }

    .dropdown-xxl-animate[data-toggle="hover"]>.dropdown-menu {
        display: block;
        margin: 0;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
    .dropdown-xxl-animate[data-toggle="hover"]>.dropdown-menu {
        transition: none;
    }
}

@media (min-width: 1400px) {
    .dropdown-xxl-animate[data-toggle="hover"]:hover>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        transform: translateX(0) translateY(-6px) scale(1);
        visibility: visible;
    }
}

@-webkit-keyframes show-dropdown {
    0% {
        opacity: 0;
        transform: translateX(0) translateY(-3px) scale(0.97);
        transition: all 0.3s ease;
    }

    to {
        opacity: 1;
        transform: translateX(0) translateY(-3px) scale(1);
    }
}

@keyframes show-dropdown {
    0% {
        opacity: 0;
        transform: translateX(0) translateY(-3px) scale(0.97);
        transition: all 0.3s ease;
    }

    to {
        opacity: 1;
        transform: translateX(0) translateY(-3px) scale(1);
    }
}

@-webkit-keyframes hide-dropdown {
    0% {
        opacity: 1;
        transform: translateX(0) translateY(-3px) scale(1);
        transition: all 0.3s ease;
    }

    to {
        opacity: 0;
        transform: translateX(0) translateY(-3px) scale(0.97);
    }
}

@keyframes hide-dropdown {
    0% {
        opacity: 1;
        transform: translateX(0) translateY(-3px) scale(1);
        transition: all 0.3s ease;
    }

    to {
        opacity: 0;
        transform: translateX(0) translateY(-3px) scale(0.97);
    }
}

.dropdown-menu .dropdown-menu {
    left: 100%;
    margin: 0;
    right: auto;
    top: 0;
}

.dropdown-submenu {
    display: block;
    position: relative;
}

.dropdown-submenu>.dropdown-menu:after {
    border: 0 !important;
}

.dropdown-submenu>.dropdown-item:after,
.dropdown-submenu>.list-group-item:after {
    content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}

.dropdown-submenu>.dropdown-item:empty:after,
.dropdown-submenu>.list-group-item:empty:after {
    margin-left: 0;
}

.dropdown-submenu>.dropdown-item:after,
.dropdown-submenu>.list-group-item:after {
    font-size: 0.75rem;
    margin-left: 0;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown-submenu[data-toggle="hover"]>.dropdown-menu {
    left: 100%;
    transform: translateX(0) translateY(-3px) scale(0.97);
}

.dropdown-submenu[data-toggle="hover"]:hover>.dropdown-menu {
    transform: translateX(0) translateY(-3px) scale(1);
}

@media (max-width: 767.98px) {
    .dropdown-submenu .dropdown-menu {
        box-shadow: none;
        padding: 0;
        top: 0;
    }

    .dropdown-submenu .dropdown-item {
        padding-left: 3rem;
    }
}

.frame-laptop {
    position: relative;
}

.frame-laptop .frame-inner {
    height: 78%;
    left: 0;
    margin: 2.5% 15% 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 70%;
}

.frame-iphone {
    position: relative;
    z-index: 100;
}

.frame-iphone .frame-inner {
    border-radius: 5%;
    height: 96%;
    left: 0;
    margin: 4% 4% 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 92%;
    z-index: -1;
}

.frame-stack {
    position: relative;
}

.frame-stack .frame {
    position: absolute;
}

.frame-stack-iphone-iphone {
    padding-bottom: 130.250482%;
}

.frame-stack-iphone-iphone>.frame-iphone:first-child {
    bottom: 0;
    left: 0;
    width: 65.5260116%;
    z-index: 1;
}

.frame-stack-iphone-iphone>.frame-iphone:last-child {
    right: 0;
    top: 0;
    width: 72.8323699%;
}

.frame-stack-iphone-laptop,
.frame-stack-laptop-iphone {
    padding-bottom: 62.4260355%;
}

.frame-stack-iphone-laptop>.frame-laptop,
.frame-stack-laptop-iphone>.frame-laptop {
    width: 91.7159763%;
}

.frame-stack-iphone-laptop>.frame-iphone,
.frame-stack-laptop-iphone>.frame-iphone {
    width: 27.9585799%;
    z-index: 1;
}

.frame-stack-laptop-iphone>.frame-laptop {
    left: 0;
    top: 0;
}

.frame-stack-iphone-laptop>.frame-laptop {
    right: 0;
    top: 0;
}

.frame-stack-laptop-iphone>.frame-iphone {
    bottom: 0;
    right: 0;
}

.frame-stack-iphone-laptop>.frame-iphone {
    bottom: 0;
    left: 0;
}

.icon {
    font-size: 1.7142857143rem;
    line-height: 1;
}

.icon svg {
    height: 1em;
    width: 1em;
}

.icon+.icon-text {
    padding-left: 1rem;
    width: calc(100% - 3rem - 1);
}

.icon-xl {
    font-size: 2.8571428571rem;
}

.icon-xl+.icon-text {
    width: calc(100% - 5rem - 1);
}

.icon-lg {
    font-size: 2.2857142857rem;
}

.icon-lg+.icon-text {
    width: calc(100% - 4rem - 1);
}

.icon-sm {
    font-size: 1.1428571429rem;
}

.icon-sm+.icon-text {
    width: calc(100% - 2rem - 1);
}

.icon-xs {
    font-size: 0.7142857143rem;
}

.icon-xs+.icon-text {
    width: calc(100% - 1.25rem - 1);
}

.icon-shape {
    align-items: center;
    border-radius: 0.375rem;
    display: inline-flex;
    height: 3rem;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    width: 3rem;
}

.icon-shape.icon-xl {
    height: 5rem;
    width: 5rem;
}

.icon-shape.icon-lg {
    height: 4rem;
    width: 4rem;
}

.icon-shape.icon-sm {
    height: 2rem;
    width: 2rem;
}

.icon-shape.icon-xs {
    height: 1.25rem;
    width: 1.25rem;
}

.list-group {
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
}

.list-group-numbered {
    counter-reset: section;
    list-style-type: none;
}

.list-group-numbered>li:before {
    content: counters(section, ".") ". ";
    counter-increment: section;
}

.list-group-item-action {
    color: #525f7f;
    text-align: inherit;
    width: 100%;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: #f5f9fc;
    color: #525f7f;
    text-decoration: none;
    z-index: 1;
}

.list-group-item-action:active {
    background-color: #e7eaf0;
    color: #525f7f;
}

.list-group-item {
    background-color: transparent;
    border: 1px solid #e7eaf0;
    color: #16192c;
    display: block;
    padding: 1.125rem 1.5rem;
    position: relative;
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.list-group-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
    background-color: transparent;
    color: #6b7b93;
    pointer-events: none;
}

.list-group-item.active {
    background-color: #f5f9fc;
    border-color: #e7eaf0;
    color: #525f7f;
    z-index: 2;
}

.list-group-item+.list-group-item {
    border-top-width: 0;
}

.list-group-item+.list-group-item.active {
    border-top-width: 1px;
    margin-top: -1px;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-left-width: 0;
    border-top-width: 1px;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.375rem;
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.375rem;
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.375rem;
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.375rem;
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.375rem;
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
    }
}

.list-group-flush {
    border-radius: 0;
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    background-color: #dedffd;
    color: #373a93;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    background-color: #c8c9e4;
    color: #373a93;
}

.list-group-item-primary.list-group-item-action.active {
    background-color: #373a93;
    border-color: #373a93;
    color: #fff;
}

.list-group-item-secondary {
    background-color: #f5f7f9;
    color: #7c8086;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    background-color: #dddee0;
    color: #7c8086;
}

.list-group-item-secondary.list-group-item-action.active {
    background-color: #7c8086;
    border-color: #7c8086;
    color: #fff;
}

.list-group-item-tertiary {
    background-color: #ffddeb;
    color: #99345c;
}

.list-group-item-tertiary.list-group-item-action:focus,
.list-group-item-tertiary.list-group-item-action:hover {
    background-color: #e6c7d4;
    color: #99345c;
}

.list-group-item-tertiary.list-group-item-action.active {
    background-color: #99345c;
    border-color: #99345c;
    color: #fff;
}

.list-group-item-success {
    background-color: #ccf5e7;
    color: #007a52;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    background-color: #b8ddd0;
    color: #007a52;
}

.list-group-item-success.list-group-item-action.active {
    background-color: #007a52;
    border-color: #007a52;
    color: #fff;
}

.list-group-item-info {
    background-color: #ccf6ff;
    color: #007f99;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    background-color: #b8dde6;
    color: #007f99;
}

.list-group-item-info.list-group-item-action.active {
    background-color: #007f99;
    border-color: #007f99;
    color: #fff;
}

.list-group-item-warning {
    background-color: #ffe8cc;
    color: #995400;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    background-color: #e6d1b8;
    color: #995400;
}

.list-group-item-warning.list-group-item-action.active {
    background-color: #995400;
    border-color: #995400;
    color: #fff;
}

.list-group-item-danger {
    background-color: #ffd6e0;
    color: #991f3d;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    background-color: #e6c1ca;
    color: #991f3d;
}

.list-group-item-danger.list-group-item-action.active {
    background-color: #991f3d;
    border-color: #991f3d;
    color: #fff;
}

.list-group-item-white {
    background-color: #fff;
    color: #999;
}

.list-group-item-white.list-group-item-action:focus,
.list-group-item-white.list-group-item-action:hover {
    background-color: #e6e6e6;
    color: #999;
}

.list-group-item-white.list-group-item-action.active {
    background-color: #999;
    border-color: #999;
    color: #fff;
}

.list-group-item-light {
    background-color: #fff;
    color: #999;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    background-color: #e6e6e6;
    color: #999;
}

.list-group-item-light.list-group-item-action.active {
    background-color: #999;
    border-color: #999;
    color: #fff;
}

.list-group-item-dark {
    background-color: #d0d1d5;
    color: #0d0f1a;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    background-color: #bbbcc0;
    color: #0d0f1a;
}

.list-group-item-dark.list-group-item-action.active {
    background-color: #0d0f1a;
    border-color: #0d0f1a;
    color: #fff;
}

.list-group {
    list-style-type: none;
}

.list-group-flush>.list-group-item {
    padding-left: 0;
    padding-right: 0;
}

.list-group-borderless>.list-group-item {
    border: 0;
}

.list-group-space .list-group-item {
    border-radius: 0.375rem;
    margin-bottom: 1.5rem;
}

.list-group-item {
    font-size: 0.875rem;
}

.list-group-img {
    border-radius: 50%;
    height: 3rem;
    margin: -0.1rem 1.2rem 0 -0.2rem;
    vertical-align: top;
    width: 3rem;
}

.list-group-content {
    flex: 1;
    min-width: 0;
}

.list-group-content p {
    line-height: 1.5;
    margin: 0.2rem 0 0;
}

.list-group-heading {
    color: #2d3748;
}

.list-group-heading>.small,
.list-group-heading>small {
    color: #8898a9;
    float: right;
    font-weight: 500;
}

.list-group-sm .list-group-item {
    font-size: 0.875rem;
    padding: 0.675rem 1.25rem;
}

.list-group-emphasized .list-group-item {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .list-group-emphasized .list-group-item {
        transition: none;
    }
}

.list-group-emphasized .list-group-item.active {
    background-color: #f5f9fc;
    border-radius: 0.375rem;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    transform: scale(1.05);
    z-index: 11;
}

.list-group-emphasized .list-group-item.active .media a {
    color: theme-color("primary");
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
	/* z-index: 9; */
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1055;
}

.modal-dialog {
    margin: 0.5rem;
    pointer-events: none;
    position: relative;
    width: auto;
}

.modal.fade .modal-dialog {
    transform: translateY(-50px);
    transition: transform 0.3s ease-out;
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    align-items: center;
    display: flex;
    min-height: calc(100% - 1rem);
}

.modal-content {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #e7eaf0;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
}

.modal-backdrop {
    background-color: #000;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1050;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.4;
}

.modal-header {
    align-items: center;
    border-bottom: 1px solid #e7eaf0;
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 1.5rem;
}

.modal-header .btn-close {
    margin: -0.75rem -0.75rem -0.75rem auto;
    padding: 0.75rem;
}

.modal-title {
    line-height: 1.625;
    margin-bottom: 0;
}

.modal-body {
    flex: 1 1 auto;
    padding: 1.5rem;
    position: relative;
}

.modal-footer {
    align-items: center;
    border-bottom-left-radius: calc(0.75rem - 1px);
    border-bottom-right-radius: calc(0.75rem - 1px);
    border-top: 1px solid #e7eaf0;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 1rem;
}

.modal-footer>* {
    margin: 0.5rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 1.75rem auto;
        max-width: 500px;
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-content {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
}

.modal-fullscreen .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
}

.modal-fullscreen .modal-header {
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-fullscreen .modal-footer {
    border-radius: 0;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw;
    }

    .modal-fullscreen-sm-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw;
    }

    .modal-fullscreen-md-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw;
    }

    .modal-fullscreen-lg-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw;
    }

    .modal-fullscreen-xl-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        height: 100%;
        margin: 0;
        max-width: none;
        width: 100vw;
    }

    .modal-fullscreen-xxl-down .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }
}

.modal-content {
    border: 0;
    border-radius: 0.75rem;
}

.modal-fluid .modal-dialog {
    margin-bottom: 0;
    margin-top: 0;
}

.modal-fluid .modal-content {
    border-radius: 0;
}

.modal-open {
    max-height: 100vh;
}

.modal-dialog-aside {
    margin: 0;
    position: absolute;
}

.modal-primary .modal-title {
    color: #fff;
}

.modal-primary .modal-footer,
.modal-primary .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-primary .modal-content {
    background-color: #0566ea;
    color: #fff;
}

.modal-primary .close>span:not(.sr-only),
.modal-primary .modal-content .heading {
    color: #fff;
}

.modal-secondary .modal-title {
    color: #000;
}

.modal-secondary .modal-footer,
.modal-secondary .modal-header {
    border-color: rgba(0, 0, 0, 0.075);
}

.modal-secondary .modal-content {
    background-color: #cfd6df;
    color: #000;
}

.modal-secondary .modal-content .heading {
    color: #000;
}

.modal-secondary .close>span:not(.sr-only),
.modal-tertiary .modal-title {
    color: #fff;
}

.modal-tertiary .modal-footer,
.modal-tertiary .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-tertiary .modal-content {
    background-color: #ff579a;
    color: #fff;
}

.modal-success .modal-title,
.modal-tertiary .close>span:not(.sr-only),
.modal-tertiary .modal-content .heading {
    color: #fff;
}

.modal-success .modal-footer,
.modal-success .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-success .modal-content {
    background-color: #28a745;
    color: #fff;
}

.modal-success .close>span:not(.sr-only),
.modal-success .modal-content .heading {
    color: #fff;
}

.modal-info .modal-title {
    color: #000;
}

.modal-info .modal-footer,
.modal-info .modal-header {
    border-color: rgba(0, 0, 0, 0.075);
}

.modal-info .modal-content {
    background-color: #00d4ff;
    color: #000;
}

.modal-info .modal-content .heading {
    color: #000;
}

.modal-info .close>span:not(.sr-only),
.modal-warning .modal-title {
    color: #fff;
}

.modal-warning .modal-footer,
.modal-warning .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-warning .modal-content {
    background-color: #ff8c00;
    color: #fff;
}

.modal-danger .modal-title,
.modal-warning .close>span:not(.sr-only),
.modal-warning .modal-content .heading {
    color: #fff;
}

.modal-danger .modal-footer,
.modal-danger .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-danger .modal-content {
    background-color: #dc3545;
    color: #fff;
}

.modal-danger .close>span:not(.sr-only),
.modal-danger .modal-content .heading {
    color: #fff;
}

.modal-white .modal-title {
    color: #000;
}

.modal-white .modal-footer,
.modal-white .modal-header {
    border-color: rgba(0, 0, 0, 0.075);
}

.modal-white .modal-content {
    background-color: #fff;
    color: #000;
}

.modal-white .modal-content .heading {
    color: #000;
}

.modal-white .close>span:not(.sr-only) {
    color: #fff;
}

.modal-light .modal-title {
    color: #000;
}

.modal-light .modal-footer,
.modal-light .modal-header {
    border-color: rgba(0, 0, 0, 0.075);
}

.modal-light .modal-content {
    background-color: #fff;
    color: #000;
}

.modal-light .modal-content .heading {
    color: #000;
}

.modal-dark .modal-title,
.modal-light .close>span:not(.sr-only) {
    color: #fff;
}

.modal-dark .modal-footer,
.modal-dark .modal-header {
    border-color: hsla(0, 0%, 100%, 0.075);
}

.modal-dark .modal-content {
    background-color: #16192c;
    color: #fff;
}

.modal-dark .close>span:not(.sr-only),
.modal-dark .modal-content .heading {
    color: #fff;
}

.modal-vertical {
    height: calc(100% - 0.5rem);
    margin: 0.5rem 0;
    max-width: 350px;
}

.modal-vertical.modal-lg {
    max-width: 450px;
}

.modal-vertical .modal-content {
    border-radius: 0.75rem 0 0 0.75rem;
    border-width: 0;
    height: inherit;
}

.modal-vertical .modal-body {
    height: inherit;
    overflow-y: auto;
}

.modal.fade .modal-dialog-vertical {
    transform: translateX(-100%);
}

.modal.show .modal-dialog-vertical {
    transform: translateX(0);
}

.modal.fixed-right {
    padding-right: 0 !important;
}

.modal.fixed-right .modal-vertical {
    margin-left: auto;
}

.modal.fixed-right.fade .modal-vertical {
    transform: translateX(100%);
}

.modal.fixed-right.show .modal-vertical {
    transform: translateX(0);
}

.offcanvas {
    background-clip: padding-box;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
}

@media (prefers-reduced-motion: reduce) {
    .offcanvas {
        transition: none;
    }
}

.offcanvas-backdrop {
    background-color: #000;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1040;
}

.offcanvas-backdrop.fade {
    opacity: 0;
}

.offcanvas-backdrop.show {
    opacity: 0.4;
}

.offcanvas-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
}

.offcanvas-header .btn-close {
    margin-bottom: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
    padding: 0.75rem;
}

.offcanvas-title {
    line-height: 1.625;
    margin-bottom: 0;
}

.offcanvas-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5rem;
}

.offcanvas-start {
    border-right: 1px solid #e7eaf0;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: 400px;
}

.offcanvas-end {
    border-left: 1px solid #e7eaf0;
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: 400px;
}

.offcanvas-top {
    border-bottom: 1px solid #e7eaf0;
    top: 0;
    transform: translateY(-100%);
}

.offcanvas-bottom,
.offcanvas-top {
    height: 30vh;
    left: 0;
    max-height: 100%;
    right: 0;
}

.offcanvas-bottom {
    border-top: 1px solid #e7eaf0;
    transform: translateY(100%);
}

.offcanvas.show {
    transform: none;
}

.navbar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between;
}

.navbar-brand {
    font-size: 1rem;
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    white-space: nowrap;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
}

.navbar-text {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.navbar-collapse {
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1;
}

.navbar-toggler-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 1.5em;
    vertical-align: middle;
    width: 1.5em;
}

.navbar-nav-scroll {
    max-height: var(--x-scroll-height, 75vh);
    overflow-y: auto;
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler,
    .navbar-expand-sm .offcanvas-header {
        display: none;
    }

    .navbar-expand-sm .offcanvas {
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000;
    }

    .navbar-expand-sm .offcanvas-bottom,
    .navbar-expand-sm .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto;
    }

    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler,
    .navbar-expand-md .offcanvas-header {
        display: none;
    }

    .navbar-expand-md .offcanvas {
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000;
    }

    .navbar-expand-md .offcanvas-bottom,
    .navbar-expand-md .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto;
    }

    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler,
    .navbar-expand-lg .offcanvas-header {
        display: none;
    }

    .navbar-expand-lg .offcanvas {
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000;
    }

    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto;
    }

    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler,
    .navbar-expand-xl .offcanvas-header {
        display: none;
    }

    .navbar-expand-xl .offcanvas {
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000;
    }

    .navbar-expand-xl .offcanvas-bottom,
    .navbar-expand-xl .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto;
    }

    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0;
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible;
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xxl .navbar-toggler,
    .navbar-expand-xxl .offcanvas-header {
        display: none;
    }

    .navbar-expand-xxl .offcanvas {
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        transform: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000;
    }

    .navbar-expand-xxl .offcanvas-bottom,
    .navbar-expand-xxl .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto;
    }

    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0;
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler,
.navbar-expand .offcanvas-header {
    display: none;
}

.navbar-expand .offcanvas {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    bottom: 0;
    flex-grow: 1;
    position: inherit;
    transform: none;
    transition: none;
    visibility: visible !important;
    z-index: 1000;
}

.navbar-expand .offcanvas-bottom,
.navbar-expand .offcanvas-top {
    border-bottom: 0;
    border-top: 0;
    height: auto;
}

.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-nav .nav-link {
    color: #16192c;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #495392;
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: #8898a9;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-toggler {
    color: #16192c;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text,
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #16192c;
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, 0.85);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, 0.85);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, 0.85);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}

.navbar {
    z-index: 100;
}

.navbar,
.navbar [class^="container"] {
    position: relative;
}

.navbar-nav .nav-link {
    font-size: 0.925rem;
    font-weight: 500;
    letter-spacing: 0;
    transition: all 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-nav .nav-link {
        transition: none;
    }
}

.navbar-text {
    font-size: 0.925rem;
}

.navbar-user {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.navbar-img-brand {
    font-size: medium;
}

.navbar-border.navbar-dark,
.navbar-border.navbar-dark .border-bottom {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
}

.navbar-border.navbar-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}

.navbar-border.navbar-light .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.navbar-dark .border-bottom {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1) !important;
}

.navbar-light .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.navbar-top {
    align-items: center;
    display: flex;
    position: relative;
}

.navbar-top .navbar-nav {
    flex-direction: row;
}

.navbar-top .navbar-nav .nav-item:last-child {
    margin-right: 0;
}

.navbar-top .navbar-nav .nav-link {
    padding: 0.5rem;
}

.navbar-collapse:before {
    border-top-style: solid;
    border-top-width: 1px;
    content: "";
    display: block;
    margin: 0.75rem -1rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-collapse:before {
        transition: none;
    }
}

.navbar-collapse.collapsing:before,
.navbar-collapse.show:before {
    opacity: 1;
}

.navbar-light .navbar-collapse:before {
    border-top-color: rgba(22, 25, 44, 0.1);
}

.navbar-light .collapse .nav:before {
    border-color: rgba(22, 25, 44, 0.1);
}

.navbar-dark .navbar-collapse:before {
    border-top-color: hsla(0, 0%, 100%, 0.2);
}

.navbar-dark .collapse .nav:before {
    border-color: hsla(0, 0%, 100%, 0.2);
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-brand img {
        height: 40px;
    }

    .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
        display: none;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        border-radius: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-expand-sm .navbar-collapse .collapse-header,
    .navbar-expand-sm .navbar-collapse:before {
        display: none;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-brand img {
        height: 40px;
    }

    .navbar-expand-md .navbar-brand.dropdown-toggle:after {
        display: none;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        border-radius: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-expand-md .navbar-collapse .collapse-header,
    .navbar-expand-md .navbar-collapse:before {
        display: none;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-brand img {
        height: 40px;
    }

    .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
        display: none;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        border-radius: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-expand-lg .navbar-collapse .collapse-header,
    .navbar-expand-lg .navbar-collapse:before {
        display: none;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-brand img {
        height: 40px;
    }

    .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
        display: none;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        border-radius: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-expand-xl .navbar-collapse .collapse-header,
    .navbar-expand-xl .navbar-collapse:before {
        display: none;
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl .navbar-brand img {
        height: 40px;
    }

    .navbar-expand-xxl .navbar-brand.dropdown-toggle:after {
        display: none;
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        border-radius: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .navbar-expand-xxl .navbar-collapse .collapse-header,
    .navbar-expand-xxl .navbar-collapse:before {
        display: none;
    }
}

.navbar-expand .navbar-brand img {
    height: 40px;
}

.navbar-expand .navbar-brand.dropdown-toggle:after {
    display: none;
}

.navbar-expand .navbar-nav .nav-link {
    border-radius: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.navbar-expand .navbar-collapse .collapse-header,
.navbar-expand .navbar-collapse:before {
    display: none;
}

.navbar-toggler-icon {
    height: 1.25em;
    width: 1.25em;
}

.navbar-search .input-group {
    border: 0 solid;
    border-radius: 0.5rem;
    transition: background-color 0.4s linear;
    transition-delay: 0.2s;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-search .input-group {
        transition: none;
    }
}

.navbar-search .input-group .input-group-text {
    background-color: transparent;
    border: 0;
    padding-left: 1rem;
}

.navbar-search .form-control {
    background-color: transparent;
    border: 0;
    transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
    width: 250px;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-search .form-control {
        transition: none;
    }
}

.navbar-search .focused .input-group .form-control {
    width: 380px;
}

.navbar-search .close {
    display: none;
}

.navbar-search-dark .input-group {
    background-color: #05060a;
    border-color: #05060a;
}

.navbar-search-dark .input-group-text {
    color: hsla(0, 0%, 100%, 0.6);
}

.navbar-search-dark .form-control {
    color: hsla(0, 0%, 100%, 0.9);
}

.navbar-search-dark .form-control::-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.6);
}

.navbar-search-dark .form-control::placeholder {
    color: hsla(0, 0%, 100%, 0.6);
}

.navbar-search-dark .focused .input-group {
    background-color: #000;
    border-color: #000;
}

.navbar-search-light .input-group {
    background-color: #f2f2f2;
    border-color: rgba(0, 0, 0, 0.6);
}

.navbar-search-light .input-group-text {
    color: rgba(0, 0, 0, 0.6);
}

.navbar-search-light .form-control {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-search-light .form-control::-moz-placeholder {
    color: rgba(0, 0, 0, 0.6);
}

.navbar-search-light .form-control::placeholder {
    color: rgba(0, 0, 0, 0.6);
}

.navbar-search-light .focused .input-group {
    background-color: #e6e6e6;
    border-color: rgba(0, 0, 0, 0.9);
}

.navbar-vertical .navbar-nav {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.navbar-vertical .navbar-nav .nav-link {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    white-space: nowrap;
}

.navbar-vertical .navbar-nav .nav-link.active {
    position: relative;
}

.navbar-vertical .navbar-nav .nav-link i,
.navbar-vertical .navbar-nav .nav-link svg {
    min-width: 1.875rem;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
    border: none;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
    margin-left: 0.5rem;
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    background-repeat: no-repeat;
    background-size: 0.875rem 0.875rem;
    content: "";
    display: inline-block;
    height: 0.875rem;
    margin-left: auto;
    transition: all 0.2s ease-in-out;
    width: 0.875rem;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
        transition: none;
    }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
    color: theme-color("primary");
    transform: rotate(90deg);
}

.navbar-vertical .navbar-nav>.nav-item {
    margin-top: 2px;
}

.navbar-vertical .navbar-nav .nav-sm .nav-link {
    font-size: 0.875rem;
}

.navbar-vertical .navbar-nav .nav .nav-link {
    font-weight: 400;
    padding-bottom: 0.4285714286rem;
    padding-left: 3.375rem;
    padding-top: 0.4285714286rem;
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
    padding-left: 2.8125rem;
}

.navbar-vertical.navbar-light {
    background-color: #fff;
    border-color: #e7eaf0;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link i,
.navbar-vertical.navbar-light .navbar-nav .nav-link svg {
    color: #6b7b93;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='%2316192C' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link.active,
.navbar-vertical.navbar-light .navbar-nav>.nav-item>.nav-link:hover {
    background-color: #f5f9fc;
}

.navbar-vertical.navbar-light .nav-link-text,
.navbar-vertical.navbar-light .navbar-heading {
    color: #16192c;
}

.navbar-vertical.navbar-dark {
    background-color: #16192c;
    border-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-vertical.navbar-dark .navbar-nav .nav-link i,
.navbar-vertical.navbar-dark .navbar-nav .nav-link svg {
    color: #f5f9fc;
}

.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-dark .navbar-nav>.nav-item>.nav-link.active,
.navbar-vertical.navbar-dark .navbar-nav>.nav-item>.nav-link:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-vertical.navbar-dark .nav-link-text,
.navbar-vertical.navbar-dark .navbar-heading {
    color: hsla(0, 0%, 100%, 0.85);
}

@media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm {
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent !important;
        display: block;
        max-width: 270px;
        overflow-y: auto;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 1000;
    }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-sm {
        transition: none;
    }
}

@media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand-sm .nav-link-text,
    .navbar-vertical.navbar-expand-sm .navbar-heading {
        transition: none;
    }
}

@media (min-width: 576px) {
    .navbar-vertical.navbar-expand-sm.show {
        border-radius: 0;
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-sm.show.navbar-lg {
        max-width: 320px;
    }

    .navbar-vertical.navbar-expand-sm.show.navbar-xl {
        max-width: 370px;
    }

    .navbar-vertical.navbar-expand-sm.show .nav-link-text,
    .navbar-vertical.navbar-expand-sm.show .navbar-heading {
        opacity: 1;
        pointer-events: auto;
    }

    .navbar-vertical.navbar-expand-sm.show+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-sm.hide {
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
        opacity: 0;
        pointer-events: none;
    }

    .navbar-vertical.navbar-expand-sm.hide+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-sm .navbar-brand {
        margin-right: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .navbar-vertical.navbar-expand-sm>[class*="container"] {
        align-items: stretch;
        flex-direction: column;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        opacity: 1;
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md {
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent !important;
        display: block;
        max-width: 270px;
        overflow-y: auto;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 1000;
    }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-md {
        transition: none;
    }
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand-md .nav-link-text,
    .navbar-vertical.navbar-expand-md .navbar-heading {
        transition: none;
    }
}

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md.show {
        border-radius: 0;
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-md.show.navbar-lg {
        max-width: 320px;
    }

    .navbar-vertical.navbar-expand-md.show.navbar-xl {
        max-width: 370px;
    }

    .navbar-vertical.navbar-expand-md.show .nav-link-text,
    .navbar-vertical.navbar-expand-md.show .navbar-heading {
        opacity: 1;
        pointer-events: auto;
    }

    .navbar-vertical.navbar-expand-md.show+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-md.hide {
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-md.hide .nav-link-text {
        opacity: 0;
        pointer-events: none;
    }

    .navbar-vertical.navbar-expand-md.hide+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        margin-right: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .navbar-vertical.navbar-expand-md>[class*="container"] {
        align-items: stretch;
        flex-direction: column;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        opacity: 1;
    }

    .navbar-vertical.navbar-expand-md .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg {
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent !important;
        display: block;
        max-width: 270px;
        overflow-y: auto;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 1000;
    }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-lg {
        transition: none;
    }
}

@media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand-lg .nav-link-text,
    .navbar-vertical.navbar-expand-lg .navbar-heading {
        transition: none;
    }
}

@media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.show {
        border-radius: 0;
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-lg.show.navbar-lg {
        max-width: 320px;
    }

    .navbar-vertical.navbar-expand-lg.show.navbar-xl {
        max-width: 370px;
    }

    .navbar-vertical.navbar-expand-lg.show .nav-link-text,
    .navbar-vertical.navbar-expand-lg.show .navbar-heading {
        opacity: 1;
        pointer-events: auto;
    }

    .navbar-vertical.navbar-expand-lg.show+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-lg.hide {
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
        opacity: 0;
        pointer-events: none;
    }

    .navbar-vertical.navbar-expand-lg.hide+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-lg .navbar-brand {
        margin-right: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .navbar-vertical.navbar-expand-lg>[class*="container"] {
        align-items: stretch;
        flex-direction: column;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        opacity: 1;
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl {
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent !important;
        display: block;
        max-width: 270px;
        overflow-y: auto;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 1000;
    }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xl {
        transition: none;
    }
}

@media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand-xl .nav-link-text,
    .navbar-vertical.navbar-expand-xl .navbar-heading {
        transition: none;
    }
}

@media (min-width: 1200px) {
    .navbar-vertical.navbar-expand-xl.show {
        border-radius: 0;
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-xl.show.navbar-lg {
        max-width: 320px;
    }

    .navbar-vertical.navbar-expand-xl.show.navbar-xl {
        max-width: 370px;
    }

    .navbar-vertical.navbar-expand-xl.show .nav-link-text,
    .navbar-vertical.navbar-expand-xl.show .navbar-heading {
        opacity: 1;
        pointer-events: auto;
    }

    .navbar-vertical.navbar-expand-xl.show+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xl.hide {
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
        opacity: 0;
        pointer-events: none;
    }

    .navbar-vertical.navbar-expand-xl.hide+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xl .navbar-brand {
        margin-right: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .navbar-vertical.navbar-expand-xl>[class*="container"] {
        align-items: stretch;
        flex-direction: column;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        opacity: 1;
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl {
        border-radius: 0;
        box-shadow: 0 0 0 0 transparent !important;
        display: block;
        max-width: 270px;
        overflow-y: auto;
        transition: all 0.2s ease-in-out;
        width: 100%;
        z-index: 1000;
    }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand-xxl {
        transition: none;
    }
}

@media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xxl .nav-link-text,
    .navbar-vertical.navbar-expand-xxl .navbar-heading {
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
    }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand-xxl .nav-link-text,
    .navbar-vertical.navbar-expand-xxl .navbar-heading {
        transition: none;
    }
}

@media (min-width: 1400px) {
    .navbar-vertical.navbar-expand-xxl.show {
        border-radius: 0;
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
        max-width: 320px;
    }

    .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
        max-width: 370px;
    }

    .navbar-vertical.navbar-expand-xxl.show .nav-link-text,
    .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
        opacity: 1;
        pointer-events: auto;
    }

    .navbar-vertical.navbar-expand-xxl.show+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xxl.hide {
        max-width: 270px;
    }

    .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
        opacity: 0;
        pointer-events: none;
    }

    .navbar-vertical.navbar-expand-xxl.hide+.main-content {
        margin-left: 270px;
    }

    .navbar-vertical.navbar-expand-xxl .navbar-brand {
        margin-right: 0;
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .navbar-vertical.navbar-expand-xxl>[class*="container"] {
        align-items: stretch;
        flex-direction: column;
        min-height: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-xxl .navbar-collapse {
        align-items: stretch;
        display: flex;
        flex: 1;
        flex-direction: column;
        opacity: 1;
    }

    .navbar-vertical.navbar-expand-xxl .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
}

.navbar-vertical.navbar-expand {
    border-radius: 0;
    box-shadow: 0 0 0 0 transparent !important;
    display: block;
    max-width: 270px;
    overflow-y: auto;
    transition: all 0.2s ease-in-out;
    width: 100%;
    z-index: 1000;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-vertical.navbar-expand {
        transition: none;
    }
}

.navbar-vertical.navbar-expand+.main-content {
    margin-left: 270px;
}

.navbar-vertical.navbar-expand .nav-link-text,
.navbar-vertical.navbar-expand .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

    .navbar-vertical.navbar-expand .nav-link-text,
    .navbar-vertical.navbar-expand .navbar-heading {
        transition: none;
    }
}

.navbar-vertical.navbar-expand.show {
    border-radius: 0;
    max-width: 270px;
}

.navbar-vertical.navbar-expand.show.navbar-lg {
    max-width: 320px;
}

.navbar-vertical.navbar-expand.show.navbar-xl {
    max-width: 370px;
}

.navbar-vertical.navbar-expand.show .nav-link-text,
.navbar-vertical.navbar-expand.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
}

.navbar-vertical.navbar-expand.show+.main-content {
    margin-left: 270px;
}

.navbar-vertical.navbar-expand.hide {
    max-width: 270px;
}

.navbar-vertical.navbar-expand.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
}

.navbar-vertical.navbar-expand.hide+.main-content {
    margin-left: 270px;
}

.navbar-vertical.navbar-expand .navbar-brand {
    margin-right: 0;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
}

.navbar-vertical.navbar-expand>[class*="container"] {
    align-items: stretch;
    flex-direction: column;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    opacity: 1;
}

.navbar-vertical.navbar-expand .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
}

.navbar-toggler {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    font-size: 1.25rem;
    line-height: 1;
    padding: 0.25rem 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none;
    }
}

.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 3px;
    outline: 0;
    text-decoration: none;
}

.navbar-light .navbar-toggler {
    background-color: transparent;
    border-color: transparent;
    color: #6b7b93;
}

.navbar-light .navbar-toggler:focus {
    background-color: #f5f9fc;
    color: transparent;
}

.navbar-dark .navbar-toggler {
    background-color: transparent;
    border-color: transparent;
    color: #e7eaf0;
}

.navbar-dark .navbar-toggler:focus {
    background-color: #f5f9fc;
    color: transparent;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.nav-link {
    color: #525f7f;
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .nav-link {
        transition: none;
    }
}

.nav-link:focus,
.nav-link:hover {
    color: #0566ea;
}

.nav-link.disabled {
    color: #abb6c5;
    cursor: default;
    pointer-events: none;
}

.nav-tabs {
    border-bottom: 1px solid #e7eaf0;
}

.nav-tabs .nav-link {
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: -1px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
    isolation: isolate;
}

.nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent;
    color: #abb6c5;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent transparent #0566ea;
    color: #0566ea;
}

.nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0566ea;
    color: #fff;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

@media (min-width: 992px) {
    .nav-link-badge {
        position: relative;
    }

    .nav-link-badge .badge {
        left: -5px;
        position: relative;
        top: -15px;
    }

    .nav-link-badge .nav-link-text {
        position: relative;
    }

    .nav-link-badge .nav-link-text:before {
        border-left: 1px dashed #fff;
        border-radius: 10px;
        content: "";
        display: block;
        height: 20px;
        left: -7px;
        margin: auto;
        position: absolute;
        top: 0;
        transform: translate(-50%, -50%) rotate(100deg);
        width: 20px;
    }
}

.nav.overflow-x {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px;
}

.nav.overflow-x .nav-item {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
}

.nav.overflow-x::-webkit-scrollbar {
    display: none;
}

.nav-header {
    border-bottom-width: 0;
}

.nav-application>.btn {
    background: transparent;
    color: #6b7b93;
    float: left;
    height: 123px !important;
    margin: 0 0 24px;
    width: 123px !important;
}

.nav-application>.btn:nth-child(odd) {
    margin-right: 24px;
}

.nav-application>.btn.active {
    background-color: theme-color("primary");
    box-shadow: 0 6px 6px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06) !important;
    color: color-yiq(theme-color("primary"));
}

.nav-application>.btn:hover:not(.active) {
    color: theme-color("primary");
    transform: scale(1.05);
}

.nav-dots {
    position: fixed;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 200;
}

.nav-dots .nav-link {
    display: block;
    margin: 5px;
    position: relative;
}

.nav-dots .nav-link:before {
    background: #fff;
    border: 0;
    border-radius: 50%;
    content: "";
    height: 6px;
    left: 50%;
    margin: -2px 0 0 -2px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease-in-out;
    width: 6px;
    z-index: 1;
}

.nav-dots .nav-link.active:before,
.nav-dots .nav-link:hover:before {
    height: 12px;
    width: 12px;
}

.nav-menu .nav-link {
    padding: 0.35rem 0;
}

.nav-menu .nav-link:not(.active):hover {
    color: #2d3748;
}

.nav-menu .nav-link:not(.active).active {
    font-weight: bolder;
}

.nav-tabs .nav-item {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.nav-tabs .nav-item:first-child {
    margin-left: 0;
}

.nav-tabs .nav-item:last-child {
    margin-right: 0;
}

.nav-tabs .nav-item.show .nav-link {
    border-color: transparent;
}

.nav-tabs .nav-link {
    border-bottom: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    padding: 1.25rem 0;
}

.card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 0 solid #eceef3;
    border-radius: 0.75rem;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
}

.card>hr {
    margin-left: 0;
    margin-right: 0;
}

.card>.list-group {
    border-bottom: inherit;
    border-top: inherit;
}

.card>.list-group:first-child {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-top-width: 0;
}

.card>.list-group:last-child {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-bottom-width: 0;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0;
}

.card-body {
    color: #525f7f;
    flex: 1 1 auto;
    padding: 1.5rem;
}

.card-title {
    margin-bottom: 1.5rem;
}

.card-subtitle {
    margin-top: -0.75rem;
}

.card-subtitle,
.card-text:last-child {
    margin-bottom: 0;
}

.card-link+.card-link {
    margin-left: 1.5rem;
}

.card-header {
    background-color: transparent;
    border-bottom: 0 solid #eceef3;
    color: #16192c;
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
}

.card-header:first-child {
    border-radius: 0.75rem 0.75rem 0 0;
}

.card-footer {
    background-color: transparent;
    border-top: 0 solid #eceef3;
    color: #16192c;
    padding: 1.25rem 1.5rem;
}

.card-footer:last-child {
    border-radius: 0 0 0.75rem 0.75rem;
}

.card-header-tabs {
    border-bottom: 0;
    margin-bottom: -1.25rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff;
}

.card-header-pills {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.card-img-overlay {
    border-radius: 0.75rem;
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.card-img,
.card-img-bottom {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.card-group>.card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        border-left: 0;
        margin-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0;
    }
}

.card-header-tabs {
    margin: calc(-1.5rem - 1px) 0 calc(-1.5rem + 1px);
}

.card-header-tabs .nav-item {
    margin-right: 0.75rem;
}

.card-header-tabs .nav-link.active {
    background-color: transparent;
    border-bottom-color: #0566ea;
}

.card-comment-box {
    width: 100%;
}

.card-comment-box input,
.card-comment-box textarea {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #2d3748;
    margin: 0;
    outline: none;
    padding: 0.3125rem 0;
    resize: none;
}

.card-comment-box input:focus,
.card-comment-box textarea:focus {
    background: transparent;
    box-shadow: none;
}

.card-list {
    background-color: #fff;
    border: 0 solid #eceef3;
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.card-list .card {
    box-shadow: none !important;
}

.card-list-body .card:last-of-type {
    margin-bottom: 0 !important;
}

.empty-container:after {
    align-items: center;
    border: 1px dashed #caced0;
    border-radius: 0.75rem;
    color: #caced0;
    content: attr(data-placeholder);
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    height: 0;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .empty-container:after {
        transition: none;
    }
}

.empty-container:only-child:after {
    height: 70px;
    opacity: 1;
}

.card-list-flush {
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.card-placeholder {
    background-color: transparent;
    border: 1px dashed #cfd6df;
    padding: 3rem 0;
}

.card-placeholder:hover {
    border-color: #c1c7cf;
}

.card-placeholder .card-body {
    text-align: center;
}

.card-progress {
    margin-bottom: 0.75rem;
}

.card-progress .card-body {
    padding: 1rem;
}

.card-progress .progress {
    height: 2px;
    margin: 2px 4px;
}

.omnisearch {
    background-color: transparent;
    left: 0;
    margin-top: 1rem;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transform: translateY(0);
    transition: transform 0.3s, opacity 0.15s;
    width: 100%;
    z-index: 1050;
}

@media (prefers-reduced-motion: reduce) {
    .omnisearch {
        transition: none;
    }
}

.omnisearch-container {
    margin: auto;
}

@media (max-width: 767.98px) {
    .omnisearch-container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .omnisearch-container {
        width: 580px;
    }
}

.omnisearch.show {
    opacity: 1;
    transform: translateY(10%);
}

.omnisearch .container {
    height: 100%;
    position: relative;
}

.omnisearch .omnisearch-form {
    background: #fff;
    border-radius: 0.5rem;
    display: block;
    position: relative;
    z-index: 700;
}

.omnisearch .omnisearch-form .input-group-text {
    background: transparent;
    font-size: 1.25rem;
}

.omnisearch .omnisearch-form .form-control {
    background-color: transparent;
    background-image: none;
    color: #6b7b93;
    display: block;
    font-size: 1.25rem;
    height: 58px;
}

.omnisearch .omnisearch-form .form-control::-moz-placeholder {
    color: #8898a9;
}

.omnisearch .omnisearch-form .form-control::placeholder {
    color: #8898a9;
}

.omnisearch .omnisearch-suggestions {
    background: #fff;
    border-radius: 0.5rem;
    min-height: 150px;
    opacity: 0;
    padding: 1.5rem;
    position: relative;
    transition: opacity 0.3s;
    transition-delay: 0.21s;
}

@media (prefers-reduced-motion: reduce) {
    .omnisearch .omnisearch-suggestions {
        transition: none;
    }
}

.omnisearch .omnisearch-suggestions:before {
    background: #fff;
    border-radius: 0.2rem;
    bottom: 100%;
    box-shadow: none;
    content: "";
    display: block;
    height: 16px;
    left: 20px;
    position: absolute;
    transform: rotate(-45deg) translateY(1rem);
    width: 16px;
    z-index: -5;
}

.omnisearch .omnisearch-suggestions .heading {
    color: #525f7f;
}

.omnisearch .omnisearch-suggestions .list-link span {
    color: #2d3748;
    font-weight: 600;
}

.omnisearch .omnisearch-suggestions .list-link:hover,
.omnisearch .omnisearch-suggestions .list-link:hover span {
    color: theme-color("primary");
}

.omnisearch.show .omnisearch-form,
.omnisearch.show .omnisearch-suggestions {
    pointer-events: auto;
}

.omnisearch.show .omnisearch-suggestions {
    opacity: 1;
}

.omnisearch-open {
    overflow: hidden;
}

.pagination {
    display: flex;
    list-style: none;
    padding-left: 0;
}

.page-link {
    background-color: #fff;
    border: 1px solid #cfd6df;
    color: #525f7f;
    display: block;
    position: relative;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none;
    }
}

.page-link:hover {
    background-color: #fafafa;
    border-color: #cfd6df;
    color: #16192c;
    z-index: 2;
}

.page-link:focus {
    background-color: #f5f9fc;
    box-shadow: none;
    color: #4e52d0;
    outline: 0;
    z-index: 3;
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

.page-item.active .page-link {
    background-color: #0566ea;
    border-color: #0566ea;
    color: #fff;
    z-index: 3;
}

.page-item.disabled .page-link {
    background-color: #fff;
    border-color: #cfd6df;
    color: #6b7b93;
    pointer-events: none;
}

.page-link {
    padding: 0.375rem 1rem;
}

.page-item:first-child .page-link {
    border-bottom-left-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
    border-bottom-right-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.pagination-lg .page-link {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.75rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.pagination {
    margin-bottom: 0;
}

.pagination .page-link {
    font-size: 0.875rem;
}

.pagination-lg .page-link {
    font-size: 1rem;
}

.pagination-sm .page-link {
    font-size: 0.875rem;
}

.pagination-tabs {
    border-radius: 0;
}

.pagination-tabs .page-link {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0 !important;
    border-width: 1px 0 0;
    margin-top: -1px;
    padding: 1.25rem 0.75rem;
}

.pagination-tabs .page-link:hover {
    color: #0566ea;
}

.pagination-tabs .page-item.active .page-link {
    background-color: transparent;
    border-color: #0566ea;
    color: #fff;
}

.pagination-spaced {
    border-radius: 0;
}

.pagination-spaced .page-link {
    align-items: center;
    border-radius: 0.375rem;
    display: inline-flex;
    height: 36px;
    justify-content: center;
    padding: 0;
    width: 36px;
}

.pagination-spaced.pagination-sm .page-link {
    border-radius: 0.25rem;
    height: 30px;
    width: 30px;
}

.pagination-spaced.pagination-lg .page-link {
    height: 52px;
    width: 52px;
}

.placeholder {
    background-color: currentColor;
    cursor: wait;
    display: inline-block;
    min-height: 1em;
    opacity: 0.5;
    vertical-align: middle;
}

.placeholder.btn:before {
    content: "";
    display: inline-block;
}

.placeholder-xs {
    min-height: 0.6em;
}

.placeholder-sm {
    min-height: 0.8em;
}

.placeholder-lg {
    min-height: 1.2em;
}

.placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}

.placeholder-wave {
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite;
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
}

@-webkit-keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0;
    }
}

@keyframes placeholder-wave {
    to {
        -webkit-mask-position: -200% 0;
        mask-position: -200% 0;
    }
}

.popover {
    word-wrap: break-word;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
    display: block;
    font-family: var(--x-font-sans-serif);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    left: 0;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.625;
    max-width: 276px;
    position: absolute;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    top: 0;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1070;
}

.popover .popover-arrow {
    display: block;
    height: 0.5rem;
    position: absolute;
    width: 1rem;
}

.popover .popover-arrow:after,
.popover .popover-arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    display: block;
    position: absolute;
}

.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow,
.bs-popover-top>.popover-arrow {
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow:before,
.bs-popover-top>.popover-arrow:before {
    border-top-color: transparent;
    border-width: 0.5rem 0.5rem 0;
    bottom: 0;
}

.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow:after,
.bs-popover-top>.popover-arrow:after {
    border-top-color: #fff;
    border-width: 0.5rem 0.5rem 0;
    bottom: 1px;
}

.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow,
.bs-popover-end>.popover-arrow {
    height: 1rem;
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow:before,
.bs-popover-end>.popover-arrow:before {
    border-right-color: transparent;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: 0;
}

.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow:after,
.bs-popover-end>.popover-arrow:after {
    border-right-color: #fff;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow,
.bs-popover-bottom>.popover-arrow {
    top: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow:before,
.bs-popover-bottom>.popover-arrow:before {
    border-bottom-color: transparent;
    border-width: 0 0.5rem 0.5rem;
    top: 0;
}

.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow:after,
.bs-popover-bottom>.popover-arrow:after {
    border-bottom-color: #fff;
    border-width: 0 0.5rem 0.5rem;
    top: 1px;
}

.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
    border-bottom: 1px solid #fff;
    content: "";
    display: block;
    left: 50%;
    margin-left: -0.5rem;
    position: absolute;
    top: 0;
    width: 1rem;
}

.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow,
.bs-popover-start>.popover-arrow {
    height: 1rem;
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
}

.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow:before,
.bs-popover-start>.popover-arrow:before {
    border-left-color: transparent;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: 0;
}

.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow:after,
.bs-popover-start>.popover-arrow:after {
    border-left-color: #fff;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: 1px;
}

.popover-header {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
    color: #16192c;
    font-size: 1rem;
    margin-bottom: 0;
    padding: 0.75rem;
}

.popover-header:empty {
    display: none;
}

.popover-body {
    color: #525f7f;
    padding: 0.75rem;
}

.popover-header {
    font-weight: 600;
}

.popover-primary {
    background-color: #0566ea;
}

.popover-primary .popover-header {
    background-color: #0566ea;
    color: #fff;
}

.popover-primary .popover-body {
    color: #fff;
}

.popover-primary .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-primary.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-primary.bs-popover-top .arrow:after {
    border-top-color: #0566ea;
}

.popover-primary.bs-popover-right .arrow:after {
    border-right-color: #0566ea;
}

.popover-primary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-primary.bs-popover-bottom .arrow:after {
    border-bottom-color: #0566ea;
}

.popover-primary.bs-popover-left .arrow:after {
    border-left-color: #0566ea;
}

.popover-secondary {
    background-color: #cfd6df;
}

.popover-secondary .popover-header {
    background-color: #cfd6df;
    color: #000;
}

.popover-secondary .popover-body {
    color: #000;
}

.popover-secondary .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
}

.popover-secondary.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-secondary.bs-popover-top .arrow:after {
    border-top-color: #cfd6df;
}

.popover-secondary.bs-popover-right .arrow:after {
    border-right-color: #cfd6df;
}

.popover-secondary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-secondary.bs-popover-bottom .arrow:after {
    border-bottom-color: #cfd6df;
}

.popover-secondary.bs-popover-left .arrow:after {
    border-left-color: #cfd6df;
}

.popover-tertiary {
    background-color: #ff579a;
}

.popover-tertiary .popover-header {
    background-color: #ff579a;
    color: #fff;
}

.popover-tertiary .popover-body {
    color: #fff;
}

.popover-tertiary .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-tertiary.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-tertiary.bs-popover-top .arrow:after {
    border-top-color: #ff579a;
}

.popover-tertiary.bs-popover-right .arrow:after {
    border-right-color: #ff579a;
}

.popover-tertiary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-tertiary.bs-popover-bottom .arrow:after {
    border-bottom-color: #ff579a;
}

.popover-tertiary.bs-popover-left .arrow:after {
    border-left-color: #ff579a;
}

.popover-success {
    background-color: #28a745;
}

.popover-success .popover-header {
    background-color: #28a745;
    color: #fff;
}

.popover-success .popover-body {
    color: #fff;
}

.popover-success .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-success.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-success.bs-popover-top .arrow:after {
    border-top-color: #28a745;
}

.popover-success.bs-popover-right .arrow:after {
    border-right-color: #28a745;
}

.popover-success.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-success.bs-popover-bottom .arrow:after {
    border-bottom-color: #28a745;
}

.popover-success.bs-popover-left .arrow:after {
    border-left-color: #28a745;
}

.popover-info {
    background-color: #00d4ff;
}

.popover-info .popover-header {
    background-color: #00d4ff;
    color: #000;
}

.popover-info .popover-body {
    color: #000;
}

.popover-info .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
}

.popover-info.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-info.bs-popover-top .arrow:after {
    border-top-color: #00d4ff;
}

.popover-info.bs-popover-right .arrow:after {
    border-right-color: #00d4ff;
}

.popover-info.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-info.bs-popover-bottom .arrow:after {
    border-bottom-color: #00d4ff;
}

.popover-info.bs-popover-left .arrow:after {
    border-left-color: #00d4ff;
}

.popover-warning {
    background-color: #ff8c00;
}

.popover-warning .popover-header {
    background-color: #ff8c00;
    color: #fff;
}

.popover-warning .popover-body {
    color: #fff;
}

.popover-warning .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-warning.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-warning.bs-popover-top .arrow:after {
    border-top-color: #ff8c00;
}

.popover-warning.bs-popover-right .arrow:after {
    border-right-color: #ff8c00;
}

.popover-warning.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-warning.bs-popover-bottom .arrow:after {
    border-bottom-color: #ff8c00;
}

.popover-warning.bs-popover-left .arrow:after {
    border-left-color: #ff8c00;
}

.popover-danger {
    background-color: #dc3545;
}

.popover-danger .popover-header {
    background-color: #dc3545;
    color: #fff;
}

.popover-danger .popover-body {
    color: #fff;
}

.popover-danger .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-danger.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-danger.bs-popover-top .arrow:after {
    border-top-color: #dc3545;
}

.popover-danger.bs-popover-right .arrow:after {
    border-right-color: #dc3545;
}

.popover-danger.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-danger.bs-popover-bottom .arrow:after {
    border-bottom-color: #dc3545;
}

.popover-danger.bs-popover-left .arrow:after {
    border-left-color: #dc3545;
}

.popover-white {
    background-color: #fff;
}

.popover-white .popover-header {
    background-color: #fff;
    color: #000;
}

.popover-white .popover-body {
    color: #000;
}

.popover-white .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
}

.popover-white.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-white.bs-popover-top .arrow:after {
    border-top-color: #fff;
}

.popover-white.bs-popover-right .arrow:after {
    border-right-color: #fff;
}

.popover-white.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-white.bs-popover-bottom .arrow:after {
    border-bottom-color: #fff;
}

.popover-white.bs-popover-left .arrow:after {
    border-left-color: #fff;
}

.popover-light {
    background-color: #fff;
}

.popover-light .popover-header {
    background-color: #fff;
    color: #000;
}

.popover-light .popover-body {
    color: #000;
}

.popover-light .popover-header {
    border-color: rgba(0, 0, 0, 0.2);
}

.popover-light.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-light.bs-popover-top .arrow:after {
    border-top-color: #fff;
}

.popover-light.bs-popover-right .arrow:after {
    border-right-color: #fff;
}

.popover-light.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-light.bs-popover-bottom .arrow:after {
    border-bottom-color: #fff;
}

.popover-light.bs-popover-left .arrow:after {
    border-left-color: #fff;
}

.popover-dark {
    background-color: #16192c;
}

.popover-dark .popover-header {
    background-color: #16192c;
    color: #fff;
}

.popover-dark .popover-body {
    color: #fff;
}

.popover-dark .popover-header {
    border-color: hsla(0, 0%, 100%, 0.2);
}

.popover-dark.bs-popover-auto[data-popper-placement^="top"] .arrow:after,
.popover-dark.bs-popover-top .arrow:after {
    border-top-color: #16192c;
}

.popover-dark.bs-popover-right .arrow:after {
    border-right-color: #16192c;
}

.popover-dark.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after,
.popover-dark.bs-popover-bottom .arrow:after {
    border-bottom-color: #16192c;
}

.popover-dark.bs-popover-left .arrow:after {
    border-left-color: #16192c;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position-x: 0.5rem;
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 0.5rem;
    }
}

.progress {
    background-color: #e7eaf0;
    border-radius: 50rem;
    box-shadow: 0 0 0 0 transparent;
    font-size: 0.75rem;
    height: 0.5rem;
}

.progress,
.progress-bar {
    display: flex;
    overflow: hidden;
}

.progress-bar {
    background-color: theme-color("primary");
    color: #fff;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: width 0.6s ease;
    white-space: nowrap;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, 0.15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, 0.15) 0, hsla(0, 0%, 100%, 0.15) 75%, transparent 0, transparent);
    background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.progress-wrapper {
    padding-top: 1.5rem;
    position: relative;
}

.progress-wrapper .progress {
    margin-bottom: 1rem;
}

.progress-inverse {
    background-color: inverse(#e7eaf0);
}

.progress-heading {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0 0 2px;
    padding: 0;
}

.progress-text {
    margin-bottom: 0;
}

.progress-lg {
    height: 1rem;
}

.progress-md {
    height: 0.75rem;
}

.progress-sm {
    height: 0.375rem;
}

.progress-xs {
    height: 0.125rem;
}

.progress-group {
    position: relative;
}

.progress-prepend-icon {
    font-size: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.progress-prepend-icon:not(:first-child) {
    right: -17px;
}

.progress-prepend-icon:not(:last-child) {
    left: -17px;
}

.progress-circle {
    height: 100px;
    width: 100px;
}

.progress-circle .progressbar-text {
    color: #16192c;
    font-size: 1.5rem;
    font-weight: 600;
}

.progress-circle .h1,
.progress-circle .h2,
.progress-circle .h3,
.progress-circle [class^="display"] {
    color: #16192c;
}

.progress-circle svg path {
    stroke-linecap: round;
}

.progress-circle.progress-sm {
    height: 60px;
    width: 60px;
}

.progress-circle.progress-sm .progressbar-text {
    font-size: 0.875rem;
}

.progress-circle.progress-lg {
    height: 140px;
    width: 140px;
}

.progress-circle.progress-lg .progressbar-text {
    font-size: 1.25rem;
}

.progress-label {
    color: #16192c;
    left: 0;
}

.progress-label,
.progress-percentage {
    display: inline-block;
    position: absolute;
    top: 0;
}

.progress-percentage {
    right: 0;
}

.progress-tooltip {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    bottom: 8px;
    color: #fff;
    display: inline-block;
    font-size: 0.7rem;
    line-height: 1;
    margin-left: -15px;
    padding: 0.25rem 0.375rem;
    position: relative;
}

.progress-tooltip:after {
    border: 5px solid rgba(51, 51, 51, 0);
    border-top-color: rgba(0, 0, 0, 0.8);
    content: " ";
    height: 0;
    left: 10px;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 0;
}

.shape-container {
    color: #fff;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transform: translateZ(0);
    width: 100%;
    z-index: 2;
}

.shape-container svg {
    display: block;
    pointer-events: none;
    vertical-align: baseline;
}

.shape-position-top {
    margin-top: -1px;
    top: 0;
}

.shape-position-bottom {
    bottom: 0;
    margin-bottom: -1px;
}

.shape-orientation-inverse {
    transform: rotate(180deg);
}

.shape-line {
    min-height: 19px;
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(1turn);
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(1turn);
    }
}

.spinner-border {
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    border: 0.25em solid;
    border-radius: 50%;
    border-right: 0.25em solid transparent;
    display: inline-block;
    height: 2rem;
    vertical-align: -0.125em;
    width: 2rem;
}

.spinner-border-sm {
    border-width: 0.2em;
    height: 1rem;
    width: 1rem;
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}

.spinner-grow {
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    height: 2rem;
    opacity: 0;
    vertical-align: -0.125em;
    width: 2rem;
}

.spinner-grow-sm {
    height: 1rem;
    width: 1rem;
}

@media (prefers-reduced-motion: reduce) {

    .spinner-border,
    .spinner-grow {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
    }
}

.table {
    --x-table-bg: transparent;
    --x-table-accent-bg: transparent;
    --x-table-striped-color: #525f7f;
    --x-table-striped-bg: rgba(0, 0, 0, 0.05);
    --x-table-active-color: #525f7f;
    --x-table-active-bg: rgba(0, 0, 0, 0.1);
    --x-table-hover-color: #525f7f;
    --x-table-hover-bg: rgba(0, 0, 0, 0.02);
    border-color: #e7eaf0;
    color: #525f7f;
    margin-bottom: 1rem;
    vertical-align: middle;
    width: 100%;
}

.table> :not(caption)>*>* {
    background-color: var(--x-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
    padding: 1rem 1.5rem;
}

.table>tbody {
    vertical-align: inherit;
}

.table>thead {
    vertical-align: bottom;
}

.table> :not(:first-child) {
    border-top: 2px solid #e7eaf0;
}

.caption-top {
    caption-side: top;
}

.table-sm> :not(caption)>*>* {
    padding: 0.5rem;
}

.table-bordered> :not(caption)>* {
    border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
    border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
    border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
    border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --x-table-accent-bg: var(--x-table-striped-bg);
    color: var(--x-table-striped-color);
}

.table-active {
    --x-table-accent-bg: var(--x-table-active-bg);
    color: var(--x-table-active-color);
}

.table-hover>tbody>tr:hover>* {
    --x-table-accent-bg: var(--x-table-hover-bg);
    color: var(--x-table-hover-color);
}

.table-primary {
    --x-table-bg: #dedffd;
    --x-table-striped-bg: #d3d4f0;
    --x-table-striped-color: #000;
    --x-table-active-bg: #c8c9e4;
    --x-table-active-color: #000;
    --x-table-hover-bg: #dadbf8;
    --x-table-hover-color: #000;
    border-color: #c8c9e4;
    color: #000;
}

.table-secondary {
    --x-table-bg: #f5f7f9;
    --x-table-striped-bg: #e9ebed;
    --x-table-striped-color: #000;
    --x-table-active-bg: #dddee0;
    --x-table-active-color: #000;
    --x-table-hover-bg: #f0f2f4;
    --x-table-hover-color: #000;
    border-color: #dddee0;
    color: #000;
}

.table-success {
    --x-table-bg: #ccf5e7;
    --x-table-striped-bg: #c2e9db;
    --x-table-striped-color: #000;
    --x-table-active-bg: #b8ddd0;
    --x-table-active-color: #000;
    --x-table-hover-bg: #c8f0e2;
    --x-table-hover-color: #000;
    border-color: #b8ddd0;
    color: #000;
}

.table-info {
    --x-table-bg: #ccf6ff;
    --x-table-striped-bg: #c2eaf2;
    --x-table-striped-color: #000;
    --x-table-active-bg: #b8dde6;
    --x-table-active-color: #000;
    --x-table-hover-bg: #c8f1fa;
    --x-table-hover-color: #000;
    border-color: #b8dde6;
    color: #000;
}

.table-warning {
    --x-table-bg: #ffe8cc;
    --x-table-striped-bg: #f2dcc2;
    --x-table-striped-color: #000;
    --x-table-active-bg: #e6d1b8;
    --x-table-active-color: #000;
    --x-table-hover-bg: #fae3c8;
    --x-table-hover-color: #000;
    border-color: #e6d1b8;
    color: #000;
}

.table-danger {
    --x-table-bg: #ffd6e0;
    --x-table-striped-bg: #f2cbd5;
    --x-table-striped-color: #000;
    --x-table-active-bg: #e6c1ca;
    --x-table-active-color: #000;
    --x-table-hover-bg: #fad2dc;
    --x-table-hover-color: #000;
    border-color: #e6c1ca;
    color: #000;
}

.table-light {
    --x-table-bg: #fff;
    --x-table-striped-bg: #f2f2f2;
    --x-table-striped-color: #000;
    --x-table-active-bg: #e6e6e6;
    --x-table-active-color: #000;
    --x-table-hover-bg: #fafafa;
    --x-table-hover-color: #000;
    border-color: #e6e6e6;
    color: #000;
}

.table-dark {
    --x-table-bg: #16192c;
    --x-table-striped-bg: #222537;
    --x-table-striped-color: #fff;
    --x-table-active-bg: #2d3041;
    --x-table-active-color: #fff;
    --x-table-hover-bg: #1b1e30;
    --x-table-hover-color: #fff;
    border-color: #2d3041;
    color: #fff;
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
    }
}

.table {
    margin-bottom: 0;
}

.table thead th {
    border-bottom-width: 1px;
    font-size: 0.675rem;
    font-weight: 500;
    letter-spacing: 0.025em;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
}

.table tbody th,
.table td {
    font-size: 0.8125rem;
}

.table td {
    white-space: nowrap;
}

.table td .progress {
    height: 3px;
    margin: 0;
    width: 120px;
}

.table.table-dark th,
.table .table-dark th {
    background-color: #1f2234;
    color: #686a76;
}

.table.table-dark th a,
.table .table-dark th a {
    color: #686a76;
}

.table.table-light th,
.table .table-light th {
    background-color: #f5f9fc;
    color: #525f7f;
}

.table.table-light th a,
.table .table-light th a {
    color: #525f7f;
}

.table-spaced {
    border-collapse: separate;
    border-spacing: 0 1rem;
}

.table-spaced thead th {
    border: 0;
}

.table-spaced tbody tr td,
.table-spaced tbody tr th,
.table-spaced thead tr td,
.table-spaced thead tr th {
    background-color: transparent;
    border-bottom: 1px solid #e7eaf0 !important;
    border-top: 1px solid #e7eaf0 !important;
    position: relative;
}

.table-spaced tbody tr td:first-child,
.table-spaced tbody tr th:first-child,
.table-spaced thead tr td:first-child,
.table-spaced thead tr th:first-child {
    border-left: 1px solid #e7eaf0 !important;
    border-radius: 0.375rem 0 0 0.375rem;
}

.table-spaced tbody tr td:first-child:after,
.table-spaced tbody tr th:first-child:after,
.table-spaced thead tr td:first-child:after,
.table-spaced thead tr th:first-child:after {
    border-left: 1px solid #e7eaf0;
    border-radius: 0.375rem 0 0 0.375rem;
}

.table-spaced tbody tr td:last-child,
.table-spaced tbody tr th:last-child,
.table-spaced thead tr td:last-child,
.table-spaced thead tr th:last-child {
    border-radius: 0 0.375rem 0.375rem 0;
    border-right: 1px solid #e7eaf0 !important;
}

.table-spaced tbody tr {
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
}

.table-flush td,
.table-flush th {
    border-left: 0;
    border-right: 0;
}

.table-flush thead>tr th {
    border-top: 0;
}

.table-flush tbody>tr:last-child td,
.table-flush tbody>tr:last-child th {
    border-bottom: 0;
}

.toast {
    background-clip: padding-box;
    background-color: hsla(0, 0%, 100%, 0.85);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    box-shadow: 0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    font-size: 0.875rem;
    max-width: 100%;
    pointer-events: auto;
    width: 350px;
}

.toast.showing {
    opacity: 0;
}

.toast:not(.show) {
    display: none;
}

.toast-container {
    max-width: 100%;
    pointer-events: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.toast-container> :not(:last-child) {
    margin-bottom: 1.5rem;
}

.toast-header {
    align-items: center;
    background-clip: padding-box;
    background-color: hsla(0, 0%, 100%, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
    color: #6b7b93;
    display: flex;
    padding: 0.5rem 0.75rem;
}

.toast-header .btn-close {
    margin-left: 0.75rem;
    margin-right: -0.375rem;
}

.toast-body {
    word-wrap: break-word;
    padding: 0.75rem;
}

.tooltip {
    word-wrap: break-word;
    display: block;
    font-family: var(--x-font-sans-serif);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.625;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1080;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .tooltip-arrow {
    display: block;
    height: 0.4rem;
    position: absolute;
    width: 0.8rem;
}

.tooltip .tooltip-arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    position: absolute;
}

.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: #000;
    border-width: 0.4rem 0.4rem 0;
    top: -1px;
}

.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    height: 0.8rem;
    left: 0;
    width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
    border-right-color: #000;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    right: -1px;
}

.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: #000;
    border-width: 0 0.4rem 0.4rem;
    bottom: -1px;
}

.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
    padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    height: 0.8rem;
    right: 0;
    width: 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
    border-left-color: #000;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    left: -1px;
}

.tooltip-inner {
    background-color: #000;
    border-radius: 0.375rem;
    color: #fff;
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    text-align: center;
}

.article {
    color: #525f7f;
    font-size: 1rem;
    line-height: 1.9;
}

.article>p {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}

.article>.h2,
.article>h2 {
    font-size: 1.5em;
    font-weight: bolder;
    letter-spacing: -0.025em;
    line-height: 1.3333333;
    margin-bottom: 1em;
    margin-top: 2em;
}

.article>.h2:first-child,
.article>h2:first-child {
    margin-top: 1em;
}

.article>.h3,
.article>h3 {
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
}

.article>.h4,
.article>h4 {
    font-size: 1em;
    font-weight: 500;
    margin-top: 2em;
}

.article>img {
    border-radius: 0.375rem;
}

.article>figure,
.article>img,
.article>video {
    margin-bottom: 2em;
    margin-top: 2em;
}

.article pre {
    background-color: #16192c;
    border-radius: 0.375rem;
    color: #fff;
    font-size: 0.875em;
    line-height: 1.7;
    margin-bottom: 1.75em;
    margin-top: 1.75em;
    overflow-x: auto;
    padding: 1rem;
}

.article .h2+*,
.article .h3+*,
.article> :first-child,
.article h2+*,
.article h3+*,
.article hr+* {
    margin-top: 0;
}

.article>hr {
    margin-bottom: 3em;
    margin-top: 3em;
}

.article>ol,
.article>ul {
    padding-left: 1.5rem;
}

.article>ol li,
.article>ul li {
    padding: 0.25rem 0;
}

.article>blockquote {
    background-color: var(--x-surface-secondary);
    border-radius: 0.5rem;
    color: #16192c;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -0.012em;
    margin-bottom: 3em;
    margin-top: 3em;
    padding: 1.5rem 3.5rem;
}

.article>blockquote>p {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #fff !important;
    border-bottom: 1px solid #f5f8fa !important;
    color: #3f4254;
    font-weight: 500;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
    padding: 0.5rem 1rem;
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
    background: #fff !important;
    border: 0 !important;
    border-radius: 0.475rem !important;
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15) !important;
    color: #3f4254;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:before {
    border-bottom: 0 !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:after {
    border-bottom-color: #fff !important;
}

.card-rounded-bottom .apexcharts-canvas svg {
    border-bottom-left-radius: 0.475rem;
    border-bottom-right-radius: 0.475rem;
}

.rounded .apexcharts-canvas svg {
    border-radius: 0.475rem !important;
}

.rounded-sm .apexcharts-canvas svg {
    border-radius: 0.275rem !important;
}

.rounded-lg .apexcharts-canvas svg {
    border-radius: 0.775rem !important;
}

.rounded-xl .apexcharts-canvas svg {
    border-radius: 1rem !important;
}

.apexcharts-bar-hover-white .apexcharts-bar-area:hover {
    fill: #fff !important;
}

.flatpickr-calendar {
    -webkit-animation: none;
    animation: none;
    background: transparent;
    border-radius: 5px;
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    direction: ltr;
    display: none;
    font-size: 14px;
    line-height: 24px;
    opacity: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    touch-action: manipulation;
    visibility: hidden;
    width: 307.875px;
}

.flatpickr-calendar.inline,
.flatpickr-calendar.open {
    max-height: 640px;
    opacity: 1;
    visibility: visible;
}

.flatpickr-calendar.open {
    display: inline-block;
    z-index: 99999;
}

.flatpickr-calendar.animate.open {
    -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
    display: block;
    position: relative;
    top: 2px;
}

.flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
    display: block;
    z-index: 999;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
    box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
    box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
    border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid #e6e6e6;
    height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    height: auto;
}

.flatpickr-calendar:after,
.flatpickr-calendar:before {
    border: solid transparent;
    content: "";
    display: block;
    height: 0;
    left: 22px;
    pointer-events: none;
    position: absolute;
    width: 0;
}

.flatpickr-calendar.arrowRight:after,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
    left: auto;
    right: 22px;
}

.flatpickr-calendar.arrowCenter:after,
.flatpickr-calendar.arrowCenter:before {
    left: 50%;
    right: 50%;
}

.flatpickr-calendar:before {
    border-width: 5px;
    margin: 0 -5px;
}

.flatpickr-calendar:after {
    border-width: 4px;
    margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
    bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
    border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
    top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
    border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
    border-top-color: #fff;
}

.flatpickr-calendar:focus {
    outline: 0;
}

.flatpickr-wrapper {
    display: inline-block;
    position: relative;
}

.flatpickr-months {
    display: flex;
}

.flatpickr-months .flatpickr-month {
    fill: rgba(0, 0, 0, 0.9);
    background: transparent;
    color: rgba(0, 0, 0, 0.9);
    flex: 1;
    height: 34px;
    line-height: 1;
    overflow: hidden;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    fill: rgba(0, 0, 0, 0.9);
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    height: 34px;
    padding: 10px;
    position: absolute;
    text-decoration: none;
    top: 0;
    z-index: 3;
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
    display: none;
}

.flatpickr-months .flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i {
    position: relative;
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    left: 0;
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
    right: 0;
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
    color: #959ea9;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #f64747;
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
    height: 14px;
    width: 14px;
}

.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
    fill: inherit;
    transition: fill 0.1s;
}

.numInputWrapper {
    height: auto;
    position: relative;
}

.numInputWrapper input,
.numInputWrapper span {
    display: inline-block;
}

.numInputWrapper input {
    width: 100%;
}

.numInputWrapper input::-ms-clear {
    display: none;
}

.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.numInputWrapper span {
    border: 1px solid rgba(57, 57, 57, 0.15);
    box-sizing: border-box;
    cursor: pointer;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    padding: 0 4px 0 2px;
    position: absolute;
    right: 0;
    width: 14px;
}

.numInputWrapper span:hover {
    background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
    background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
    content: "";
    display: block;
    position: absolute;
}

.numInputWrapper span.arrowUp {
    border-bottom: 0;
    top: 0;
}

.numInputWrapper span.arrowUp:after {
    border-bottom: 4px solid rgba(57, 57, 57, 0.6);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    top: 26%;
}

.numInputWrapper span.arrowDown {
    top: 50%;
}

.numInputWrapper span.arrowDown:after {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(57, 57, 57, 0.6);
    top: 40%;
}

.numInputWrapper span svg {
    height: auto;
    width: inherit;
}

.numInputWrapper span svg path {
    fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
    background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
    opacity: 1;
}

.flatpickr-current-month {
    color: inherit;
    display: inline-block;
    font-size: 135%;
    font-weight: 300;
    height: 34px;
    left: 12.5%;
    line-height: inherit;
    line-height: 1;
    padding: 7.48px 0 0;
    position: absolute;
    text-align: center;
    transform: translateZ(0);
    width: 75%;
}

.flatpickr-current-month span.cur-month {
    color: inherit;
    display: inline-block;
    font-family: inherit;
    font-weight: 700;
    margin-left: 0.5ch;
    padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
    background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
    display: inline-block;
    width: 6ch;
    width: 7ch\0;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: 0;
    padding: 0 0 0 0.5ch;
    vertical-align: initial;
}

.flatpickr-current-month input.cur-year:focus {
    outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
    background: transparent;
    color: rgba(0, 0, 0, 0.5);
    font-size: 100%;
    pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    appearance: menulist;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    background: transparent;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    height: auto;
    line-height: inherit;
    margin: -1px 0 0;
    outline: none;
    padding: 0 0 0 0.5ch;
    position: relative;
    vertical-align: initial;
    width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
    outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: transparent;
    outline: none;
    padding: 0;
}

.flatpickr-weekdays {
    align-items: center;
    background: transparent;
    display: flex;
    height: 28px;
    overflow: hidden;
    text-align: center;
    width: 100%;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
}

span.flatpickr-weekday {
    background: transparent;
    color: rgba(0, 0, 0, 0.54);
    cursor: default;
    display: block;
    flex: 1;
    font-size: 90%;
    font-weight: bolder;
    line-height: 1;
    margin: 0;
    text-align: center;
}

.dayContainer,
.flatpickr-weeks {
    padding: 1px 0 0;
}

.flatpickr-days {
    align-items: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 307.875px;
}

.flatpickr-days:focus {
    outline: 0;
}

.dayContainer {
    box-sizing: border-box;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-around;
    max-width: 307.875px;
    min-width: 307.875px;
    opacity: 1;
    outline: 0;
    padding: 0;
    text-align: left;
    transform: translateZ(0);
    width: 307.875px;
}

.dayContainer+.dayContainer {
    box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
    background: none;
    border: 1px solid transparent;
    border-radius: 150px;
    box-sizing: border-box;
    color: #393939;
    cursor: pointer;
    display: inline-block;
    flex-basis: 14.2857143%;
    font-weight: 400;
    height: 39px;
    justify-content: center;
    line-height: 39px;
    margin: 0;
    max-width: 39px;
    position: relative;
    text-align: center;
    width: 14.2857143%;
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    background: #e6e6e6;
    border-color: #e6e6e6;
    cursor: pointer;
    outline: 0;
}

.flatpickr-day.today {
    border-color: #959ea9;
}

.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
    background: #959ea9;
    border-color: #959ea9;
    color: #fff;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: #569ff7;
    border-color: #569ff7;
    box-shadow: none;
    color: #fff;
}

.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
    border-radius: 50px 0 0 50px;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
    border-radius: 0 50px 50px 0;
}

.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
    border-radius: 50px;
}

.flatpickr-day.inRange {
    border-radius: 0;
    box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
    background: transparent;
    border-color: transparent;
    color: rgba(57, 57, 57, 0.3);
    cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
    color: rgba(57, 57, 57, 0.1);
    cursor: not-allowed;
}

.flatpickr-day.week.selected {
    border-radius: 0;
    box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
    visibility: hidden;
}

.rangeMode .flatpickr-day {
    margin-top: 1px;
}

.flatpickr-weekwrapper {
    float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 1px 0 0 #e6e6e6;
    padding: 0 12px;
}

.flatpickr-weekwrapper .flatpickr-weekday {
    float: none;
    line-height: 28px;
    width: 100%;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
    background: transparent;
    border: none;
    color: rgba(57, 57, 57, 0.3);
    cursor: default;
    display: block;
    max-width: none;
    width: 100%;
}

.flatpickr-innerContainer {
    box-sizing: border-box;
    display: block;
    display: flex;
    overflow: hidden;
}

.flatpickr-rContainer {
    box-sizing: border-box;
    display: inline-block;
    padding: 0;
}

.flatpickr-time {
    box-sizing: border-box;
    display: block;
    display: flex;
    height: 0;
    line-height: 40px;
    max-height: 40px;
    outline: 0;
    overflow: hidden;
    text-align: center;
}

.flatpickr-time:after {
    clear: both;
    content: "";
    display: table;
}

.flatpickr-time .numInputWrapper {
    flex: 1;
    float: left;
    height: 40px;
    width: 40%;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
    width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
    width: 49%;
}

.flatpickr-time input {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    color: #393939;
    font-size: 14px;
    height: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
}

.flatpickr-time input.flatpickr-hour {
    font-weight: 700;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    font-weight: 400;
}

.flatpickr-time input:focus {
    border: 0;
    outline: 0;
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
    align-self: center;
    color: #393939;
    float: left;
    font-weight: 700;
    height: inherit;
    line-height: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 2%;
}

.flatpickr-time .flatpickr-am-pm {
    cursor: pointer;
    font-weight: 400;
    outline: 0;
    text-align: center;
    width: 18%;
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
    background: #eee;
}

.flatpickr-input[readonly] {
    cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes fpFadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: translateZ(0);
    }
}

.flatpickr-calendar {
    background: #fff;
    border: 0;
    border-radius: 0.75rem;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06);
    margin-top: -2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: auto;
}

.flatpickr-calendar:after,
.flatpickr-calendar:before {
    display: none;
}

.flatpickr-months {
    background-color: #fff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.flatpickr-months .flatpickr-month {
    height: 60px;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    align-items: center;
    color: #16192c;
    display: flex;
    height: 35px;
    justify-content: center;
    line-height: 100%;
    padding: 0;
    top: 13px;
    transition: background-color 0.4s linear;
    width: 35px;
}

@media (prefers-reduced-motion: reduce) {

    .flatpickr-months .flatpickr-next-month,
    .flatpickr-months .flatpickr-prev-month {
        transition: none;
    }
}

.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
    fill: rgba(22, 25, 44, 0.7);
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
    color: #16192c;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #16192c;
}

.flatpickr-months .flatpickr-prev-month {
    margin-left: 15px;
}

.flatpickr-months .flatpickr-next-month {
    margin-right: 15px;
}

.flatpickr-current-month {
    color: #16192c;
    font-size: 1.125rem;
    padding-top: 18px;
}

.flatpickr-current-month .numInputWrapper:hover,
.flatpickr-current-month span.cur-month:hover {
    background-color: transparent;
}

.flatpickr-current-month .numInputWrapper span {
    border: 0;
    padding: 0;
    right: -5px;
}

.flatpickr-current-month .numInputWrapper span:after {
    left: 3px;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: hsla(0, 0%, 100%, 0.7);
}

.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
    border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: hsla(0, 0%, 100%, 0.7);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
    border-top-color: #fff;
}

span.flatpickr-weekday {
    color: #16192c;
    font-weight: 600;
}

.flatpickr-day {
    border: 0;
    border-radius: 0.375rem;
    color: #16192c;
    font-size: 0.875rem;
}

.flatpickr-day.today {
    color: #0566ea !important;
}

.flatpickr-day.today:hover,
.flatpickr-day:hover {
    background-color: transparent;
    color: theme-color("primary");
}

.flatpickr-day.selected {
    background-color: #0566ea;
    color: #fff !important;
}

.flatpickr-day.selected:hover {
    background-color: #0566ea;
    color: #fff;
}

.numInputWrapper span:hover {
    background-color: transparent;
}

.flatpickr-time {
    border-top: 1px solid #f2f3f7;
}

.flatpickr-innerContainer {
    padding: 15px;
}

/*# sourceMappingURL=main.css.map */